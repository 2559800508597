import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutlineTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  helpIconContainer: {
    display: 'flex',
  },
  helpIcon: {
    fontSize: 16,
    color: theme.palette.warning.main,
  },
  helpTooltip: {
    maxWidth: 350,
  },
}));

function Label({ title, children }) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignItems="center"
      // style={{ minHeight: 38 }}
    >
      <Grid item>
        <Typography component="span">{title}</Typography>
      </Grid>
      {children && (
        <Grid item className={classes.helpIconContainer}>
          <Tooltip
            placement="top-start"
            title={
              <Typography variant="body2" color="inherit">
                {children}
              </Typography>
            }
            classes={{ tooltip: classes.helpTooltip }}
            arrow
          >
            <HelpIcon fontSize="small" className={classes.helpIcon} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

Label.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Label.defaultProps = {
  title: 'Label',
  children: 'Help Text',
};

export default React.memo(Label);

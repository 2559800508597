import {
  action,
  autorun,
  computed,
  flow,
  observable,
  makeObservable,
} from 'mobx';
import { computedFn } from 'mobx-utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import prettyBytes from 'pretty-bytes';
import UniversalCollection from './UniversalCollection';
import UniversalDocument from './UniversalDocument';
import Reconstruction from './Reconstruction';
import { RESOLUTION, MAX_EDGES } from './constants';
import { isServer } from '../utils/env';

class Model extends UniversalDocument {
  _disposeAutorun = null;
  @observable accessor _thumbnailURL = null;
  @observable accessor _fileDownloadURL = null;

  constructor(source, options) {
    super(source, options);

    if (!isServer()) {
      this._disposeAutorun = autorun(() => {
        if (
          this.thumbnailFormat &&
          this._thumbnailURL === null &&
          !this.fromCache
        ) {
          this.updateThumbnailURL();
        }

        if (
          this._fileDownloadURL === null &&
          this.filename &&
          !this.isProcessing
        ) {
          this.updateDownloadURL();
        }
      });
    }
  }

  ready() {
    return Promise.all([
      super.ready(),
      this.recsCollection.ready(),
      ...this.recs.map((p) => p.ready()),
    ]);
  }

  dispose() {
    this._disposeAutorun();
    super.dispose();
  }

  @action deleteFile() {
    return this.fileRef.delete();
  }

  @action deleteThumbnail() {
    if (!this.thumbnailRef) {
      return false;
    }
    return this.thumbnailRef.delete();
  }

  @action delete() {
    return Promise.all(this.recs.map((p) => p.delete())).then(() => {
      return Promise.all([
        this.deleteFile(),
        this.deleteThumbnail(),
        super.delete(),
      ]);
    });
  }

  get fileRef() {
    const storage = firebase.storage();
    return storage.ref(`${this.path}.${this.fileExtension}`);
  }

  @computed get fileDownloadURL() {
    return this._fileDownloadURL;
  }

  get thumbnailRef() {
    if (!this.thumbnailFormat) {
      return null;
    }
    const storage = firebase.storage();
    return storage.ref(`${this.path}.${this.thumbnailFormat}`);
  }

  get recsCollection() {
    if (this._recsCollection) return this._recsCollection;

    this._recsCollection = new UniversalCollection(
      () => `${this.path}/reconstructions`,
      {
        ...this.options,
        createDocument: (s, o) =>
          new Reconstruction(s, {
            ...this.options,
            ...o,
          }),
      },
    );
    return this._recsCollection;
  }

  @computed get fileExtension() {
    const regex = /.*?\.(b?gcode|ufp)/gm;
    const match = regex.exec(String(this.filename).toLowerCase());
    if (match) {
      return match[1];
    }
    return null;
  }

  @computed get createdAt() {
    const { createdAt } = this.data;
    return new Date(createdAt ? createdAt.seconds * 1000 : 0);
  }

  @computed get recs() {
    return this.recsCollection.docs;
  }

  @computed get recsSortedByDate() {
    return this.recs.slice().sort((a, b) => b.createdAt - a.createdAt);
  }

  @computed get recsReadySortedByDate() {
    return this.recsReady.slice().sort((a, b) => b.createdAt - a.createdAt);
  }

  @computed get latestRec() {
    const recs = this.recsSortedByDate;
    return recs.length > 0 ? recs[0] : null;
  }

  @computed get filename() {
    return this.data.filename || null;
  }

  @computed get numLayers() {
    return this.data.numLayers || 0;
  }

  @computed get fileSize() {
    return prettyBytes(this.data.fileSize || 0);
  }

  @computed get slicer() {
    return this.data.slicer || 'N/A';
  }

  @computed get tagsAvailable() {
    return Boolean(this.data.tagsAvailable);
  }

  @computed get unsupportedCommands() {
    return Boolean(this.data.unsupportedCommands);
  }

  @computed get slicerVersion() {
    return this.data.slicerVersion || '';
  }

  @computed get minLayerHeight() {
    return this.data.minLayerHeight || 0;
  }

  @computed get maxLayerHeight() {
    return this.data.maxLayerHeight || 0;
  }

  @computed get minExtrusionWidth() {
    return this.data.minExtrusionWidth || 0;
  }

  @computed get maxExtrusionWidth() {
    return this.data.maxExtrusionWidth || 0;
  }

  @computed get numExtrusions() {
    return this.data.numExtrusions || 0;
  }

  @computed get hasActiveReconstructions() {
    return this.recs.reduce((acc, v) => acc || v.isUnderConstruction, false);
    // for (const r of this.recs) {
    //   if (r.isUnderConstruction) return true;
    // }

    // return false;
  }

  @computed get hasError() {
    return this.data.error;
  }

  @computed get hasMeta() {
    return this.data.processed;
  }

  @computed get isProcessing() {
    if (this.hasError) return false;
    return (
      !this.hasMeta || this.hasActiveReconstructions || this.progress < 100
    );
  }

  @computed get progress() {
    if (typeof this.data.uploadProgress !== 'number') {
      return 100;
    }
    return this.data.uploadProgress;
  }

  @computed get status() {
    if (this.progress < 100) {
      return 'Uploading';
    }
    if (this.hasError) {
      return 'Invalid file';
    }
    if (this.isProcessing) {
      return 'Processing';
    }
    if (this.latestRec && this.latestRec.isReady) {
      return 'Loading preview';
    }
    return 'No preview';
  }

  @computed get recsReady() {
    return this.recs.slice().filter((x) => x.isReady);
  }

  @computed get recsSortedBySize() {
    return this.recsReady.sort((a, b) => a.fileSizeBytes - b.fileSizeBytes);
  }

  @computed get numSTLFiles() {
    return this.recsReady.length;
  }

  @computed get previewURL() {
    if (this.recsReady.length === 0) {
      return null;
    }
    return this.recsReadySortedByDate[0].fileDownloadURL;
  }

  @computed get thumbnail() {
    return this.data.thumbnail || null;
  }

  @computed get thumbnailFormat() {
    return this.thumbnail ? this.thumbnail.format : null;
  }

  @computed get thumbnailURL() {
    return this._thumbnailURL;
  }

  @computed get selectedRec() {
    const id = this.getLocal('selectedRec', null);
    if (id === null) {
      return null;
    }
    return this.recs.find((r) => r.id === id) || null;
  }

  @computed get startLayer() {
    return this.getLocal('startLayer', 0);
  }

  @computed get unsortedObjects() {
    return this.data.objects || [];
  }

  @computed get objects() {
    return this.unsortedObjects.slice().sort((a, b) => a.id - b.id);
  }

  @computed get selectedObjectUID() {
    if (this.unsortedObjects.length === 0) {
      return null;
    }
    return this.data.selectedObject || this.objects[0].uid;
  }

  @computed get selectedObject() {
    return this.unsortedObjects.find((o) => o.uid === this.selectedObjectUID);
  }

  @action setFilename(filename) {
    this.update({ filename });
  }

  @action setProcessed(val) {
    this.update({ processed: val });
  }

  @action setUploadProgress(p) {
    this.update({ uploadProgress: p });
  }

  @action uploadFile(file) {
    const uploadTask = this.fileRef.put(file);
    const that = this;

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      that.setUploadProgress(progress);
    });

    return uploadTask;
  }

  addReconstruction = flow(function* ({
    resolution = RESOLUTION.default,
    maxEdges = MAX_EDGES.defaultFloat,
    optimizeLayers = true,
    startLayer = 0,
    object = null,
  }) {
    const tp = (v) => parseInt(v * 100);
    const layers = optimizeLayers ? 'on' : 'off';
    const postfix = `_${tp(resolution)}um_${layers}_${tp(maxEdges)}%`;
    const prefix = this.selectedObject
      ? this.selectedObject.name.split('.')[0]
      : this.filename.split('.')[0];
    const filename = prefix + postfix + '.stl';
    const rec = yield this.recsCollection.add({
      createdAt: new Date(),
      resolution,
      maxEdges,
      optimizeLayers,
      startLayer,
      processed: false,
      filename,
      object,
    });

    return rec;
  });

  @action async deleteReconstruction(rec) {
    if (this.selectedRec && this.selectedRec.id === rec.id) {
      const recs = this.recsSortedByDate.filter((r) => r.id !== rec.id);
      this.setSelectedRec(recs.length > 0 ? recs[0] : null);
    }
    await rec.delete();
  }

  @action setStartLayer(val) {
    this.prudentUpdate({ startLayer: val });
  }

  @action setSelectedRec(rec) {
    const id = rec ? rec.id : null;
    this.prudentUpdate({ selectedRec: id });
  }

  @action setThumbnailURL(v) {
    this._thumbnailURL = v;
  }

  @action selectObject(uid) {
    this.prudentUpdate({ selectedObject: uid });
  }

  @action setDownloadURL(v) {
    this._fileDownloadURL = v;
  }

  updateThumbnailURL = flow(function* () {
    const url = yield this.thumbnailRef.getDownloadURL();
    this.setThumbnailURL(url);
  });

  updateDownloadURL = flow(function* () {
    const url = yield this.fileRef.getDownloadURL();
    this.setDownloadURL(url);
    // console.log('updating download url', this._fileDownloadURL);
  });

  @action downloadFile() {
    window.open(this.fileDownloadURL, '_self');
  }
}

export default Model;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    // display: 'relative',
  },
  markContainer: {
    position: 'relative',
    // left: -2,
    top: -25,
    height: 68,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: theme.spacing(2),
  },
  bottomMark: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function Rewards({ amount }) {
  const marks = [
    { a: 0, m: 4, t: 5 },
    { a: 2, m: 8, t: 9 },
    { a: 4, m: 10, t: 10 },
    { a: 6, m: 12, t: 12 },
    { a: 10, m: 16, t: 15 },
    { a: 16, m: 32, t: 18 },
  ];
  const classes = useStyles();
  const activeMark = marks.reduce(
    (acc, val, j) => (amount >= val.a ? j : acc),
    0,
  );
  const isActive = (i) => activeMark === i;
  const getColor = (i) => (isActive(i) ? 'textPrimary' : 'textSecondary');
  // const prog = ((amount + 1) / 10) * 100;
  let prog = 2;
  if (amount >= 16) {
    prog = 100;
  } else if (amount >= 10) {
    prog = 78.5;
  } else if (amount >= 6) {
    prog = 59;
  } else if (amount >= 4) {
    prog = 39.5;
  } else if (amount >= 2) {
    prog = 20.5;
  }

  return (
    <div className={classes.root}>
      {/* {prog} */}
      {/* {amount}/{activeMark} */}
      {/* {(activeMark / marks.length) * 100} */}
      {/* <Box pl={2} pr={2}> */}
      <Box>
        <LinearProgress
          // value={(activeMark / (marks.length - 1)) * 100}
          value={prog}
          variant="determinate"
        />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {marks.map(({ a, m, t }, i) => (
          <Box className={classes.markContainer} key={`mark-${i}`}>
            <Typography variant="caption" color={getColor(i)}>
              <b>${a}</b>
            </Typography>
            <Box className={classes.bottomMark}>
              <Typography variant="caption" color={getColor(i)}>
                {t}m
              </Typography>
              <Typography variant="caption" color={getColor(i)}>
                {m}GB
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
}

Rewards.propTypes = {
  amount: PropTypes.number,
};

Rewards.defaultProps = {
  amount: 0,
};

export default observer(Rewards);

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/WarningRounded';

import Info from '../Info';
import ControlPanel from '../ControlPanel';
import NotFound from '../NotFound';
import Login from '../Login';
import Maintenance from '../Maintenance';
import './App.css';
import { useStore } from '../../store';
import { ACCEPTED_FILES } from '../../store/constants';

import { BmcLogo } from '../../components/AppIcons';
import Layout from '../../components/Layout';
import LogoWordmark from '../../components/LogoWordmark';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    // backgroundColor: theme.palette.appBar.background,
    backgroundColor: theme.palette.background.paper,
    // height: 60,
    color: theme.palette.text.primary,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  logoContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
}));

function App() {
  const classes = useStyles();
  const { auth, backdrop, user, maintenance } = useStore();
  const maintenanceView = maintenance && !auth.isAdmin;

  const handleFileChange = (e) => {
    user.uploadFiles(e.target.files);
  };

  const handleAccountClick = () => {
    user.setDialog('account');
  };

  const handleHelpClick = () => {
    user.setDialog('help');
  };

  const handleDonateClick = () => {
    user.setDialog('donate');
  };

  return (
    <Layout
      appMenuDocked={auth.signedIn}
      appBar={
        auth.signedIn &&
        !maintenanceView && (
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
              <div className={classes.logoContainer}>
                <LogoWordmark large={false} />
                {maintenance && auth.isAdmin && (
                  <WarningIcon color={maintenance ? 'error' : 'inherited'} />
                )}
              </div>
              <Fab
                component="label"
                color="primary"
                aria-label="add"
                className={classes.fabButton}
                htmlFor={'add-file'}
              >
                <AddIcon />
                <input
                  id={'add-file'}
                  type="file"
                  name={'add-file'}
                  accept={ACCEPTED_FILES}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Fab>
              <div className={classes.grow} />
              <IconButton color="inherit" onClick={handleDonateClick}>
                <BmcLogo />
              </IconButton>
              <IconButton color="inherit" onClick={handleHelpClick}>
                <HelpIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleAccountClick}
              >
                <AccountCircle />
              </IconButton>
            </Toolbar>
          </AppBar>
        )
      }
    >
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {maintenanceView ? (
        <Maintenance />
      ) : (
        <Routes>
          <Route exact path="/" element={<Info />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/cp"
            element={auth.isAdmin ? <ControlPanel /> : <NotFound />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Layout>
  );
}

App.propTypes = {};

App.defaultProps = {};

export default observer(App);

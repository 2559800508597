import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function SigninCopy({}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1" gutterBottom>
        Your email and personal information is not shared with any third
        parties, and used only as ID to link files to your account.
      </Typography>
    </div>
  );
}

SigninCopy.propTypes = {};

SigninCopy.defaultProps = {};

export default React.memo(SigninCopy);

import { flow, observable, action, runInAction } from 'mobx';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import { isServer } from '../utils/env';

class Auth {
  _cache = null;
  _options = null;
  _init = false;
  @observable accessor signedIn = false;
  @observable accessor uid = null;
  @observable accessor email = null;
  @observable accessor displayName = null;
  @observable accessor photoURL = null;
  @observable accessor isAnon = false;
  @observable accessor isAdmin = false;

  constructor(options) {
    this._options = options;
    this._cache = options.cache;

    if (this._cache && this._cache.has('_auth')) {
      const user = this._cache.get('_auth');
      this.fromFirebaseUser(user);
    }

    if (!isServer()) {
      firebase.auth().onAuthStateChanged(async (user) => {
        // autorun(async () => {
        this.updateAuthState(user);
        this._init = true;

        try {
          const tokenId = await user.getIdTokenResult();
          runInAction(() => {
            this.isAdmin = tokenId.claims.admin;
          });
        } catch (_) {
          // eslint-disable-next-line no-console
          console.log('Failed to fetch user token');
        }
        // });
      });
    }
  }

  async ready() {
    if (isServer() && this._cache) {
      this._cache.set('_auth', {
        signedIn: this.signedIn,
        uid: this.uid,
        email: this.email,
        displayName: this.displayName,
        admin: this.isAdmin,
      });
    }
  }

  updateAuthState = flow(function* (user) {
    if (user) {
      const { pathname } = window.location;
      if (!this._init && pathname === '/login') {
        // Sign out user on client with stale server session token
        firebase.auth().signOut();
      } else {
        this.fromFirebaseUser(user);
        // this.signedIn = true;
        this.setSignedIn(true);
      }
    } else {
      // redirect if session expired while client was active
      if (this.signedIn) {
        yield firebase.auth().signOut();
        yield axios.get(`/sessionLogout`);
        window.location.assign('/login');
      }

      // this.signedIn = false;
      this.setSignedIn(false);
    }
  });

  @action fromFirebaseUser(user) {
    if (typeof user.signedIn !== 'undefined') {
      this.signedIn = user.signedIn;
    }
    this.uid = user.uid;
    this.displayName = user.displayName;
    this.email = user.email;
    this.isAnon = user.isAnonymous;
    this.isAdmin = !!user.admin;
  }

  @action setSignedIn(v) {
    this.signedIn = v;
  }

  loginWithProviderResult = flow(function* (result, redirect = false) {
    const { user } = result;
    const idToken = yield user.getIdToken();
    yield axios.post(`/sessionLogin`, { idToken });
    if (redirect) {
      window.location.assign('/');
    } else {
      this.fromFirebaseUser(user);
      this.signedIn = true;
    }
  });

  loginWithGoogle = flow(function* ({ redirect = true } = {}) {
    const provider = new firebase.auth.GoogleAuthProvider();
    const result = yield firebase.auth().signInWithPopup(provider);

    yield this.loginWithProviderResult(result, redirect);
  });

  loginAnon = flow(function* ({ redirect = false } = {}) {
    const result = yield firebase.auth().signInAnonymously();

    yield this.loginWithProviderResult(result, redirect);
  });

  loginWithEmail = flow(function* ({ email, password, redirect = true } = {}) {
    const auth = firebase.auth();
    const result = yield auth.signInWithEmailAndPassword(email, password);

    yield this.loginWithProviderResult(result, redirect);
  });

  registerEmail = flow(function* ({ email, password, redirect = true } = {}) {
    const auth = firebase.auth();
    const result = yield auth.createUserWithEmailAndPassword(email, password);

    yield this.loginWithProviderResult(result, redirect);
  });

  migrate = flow(function* (loginHandler) {
    const auth = firebase.auth();
    const anonUser = auth.currentUser;
    const anonUID = anonUser.uid;
    if (!anonUser.isAnonymous) {
      throw new Error('Current user is not anonymous');
    }

    yield loginHandler();

    const user = this._options.store.user;
    user.opsCollection.add({ op: 'migrate', from: anonUID });
  });

  migrateToGoogle = flow(function* (args) {
    const that = this;
    yield this.migrate(
      flow(function* () {
        yield that.loginWithGoogle(args);
      }),
    );
  });

  migrateToEmail = flow(function* (args) {
    const that = this;
    yield this.migrate(
      flow(function* () {
        yield that.loginWithEmail(args);
      }),
    );
  });

  registerAndMigrateToEmail = flow(function* (args) {
    const that = this;
    yield this.migrate(
      flow(function* () {
        yield that.registerEmail(args);
      }),
    );
  });

  logout = flow(function* () {
    try {
      if (this.isAnon) {
        const profile = this._options.store.profile;
        yield profile.delete();
      }
      yield firebase.auth().signOut();
      yield axios.get(`/sessionLogout`);
      window.location.assign('/login');
    } catch (error) {
      console.log('Logout error:', error);
    }
  });
}

export default Auth;

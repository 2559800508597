import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import MuiAlert from '@material-ui/lab/Alert';
import Label from '../Label';

import { RESOLUTION, MAX_EDGES } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  controlDescription: {
    paddingTop: theme.spacing(1),
  },
  sliderContainer: {
    paddingTop: `${theme.spacing(2) + 1}px !important`,
    paddingLeft: `${theme.spacing(3)}px !important`,
    paddingRight: `${theme.spacing(3)}px !important`,
  },
  switch: {
    // marginLeft: -12,
  },
  selectContainer: {
    paddingBottom: theme.spacing(2),
  },
  popper: {
    zIndex: 999999,
  },
}));

function ControlItem({ label, control, children }) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={0} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">{label}</Typography>
            <Divider />
          </Grid>
          <Grid item>{control}</Grid>
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}

ControlItem.propTypes = {
  control: PropTypes.node,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function ReconstructionControls({
  resolution,
  maxEdges,
  optimizeLayers,
  startLayer,
  numLayers,
  objects,
  selectedObject,
  resolutionExceeded,
  onResolutionChange,
  onMaxEdgesChange,
  onOptimizeLayersChange,
  onStartLayerChange,
  onSelectObject,
}) {
  const classes = useStyles();
  const resolutionRef = useRef(null);

  const handleResolutionChange = (e, val) => {
    const v = val || e.target.value;
    const res = !isNaN(parseFloat(v)) ? parseFloat(v) : v;
    onResolutionChange(res);
  };

  const handleResolutionBlur = (e) => {
    if (isNaN(parseFloat(e.target.value))) {
      onResolutionChange(RESOLUTION.default);
    } else {
      const fv = parseFloat(e.target.value);
      if (fv > RESOLUTION.max || fv < RESOLUTION.min) {
        onResolutionChange(RESOLUTION.default);
      }
    }
  };

  const handleMaxEdgesBlur = (e) => {
    const iv = parseInt(e.target.value);
    if (iv > MAX_EDGES.max || iv < MAX_EDGES.min) {
      onMaxEdgesChange(MAX_EDGES.default);
    }
  };

  const handleObjectChange = (e) => {
    onSelectObject(e.target.value);
  };

  return (
    <Grid className={classes.root} container direction="column" spacing={0}>
      <Popper
        className={classes.popper}
        open={resolutionRef.current ? resolutionExceeded : false}
        anchorEl={resolutionRef && resolutionRef.current}
        placement="top"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <MuiAlert elevation={6} severity="info">
              Resolutions below 0.55mm are available for supporters who have
              made a donation of $2 or more.
            </MuiAlert>
          </Fade>
        )}
      </Popper>
      <Grid item>
        <ControlItem label="Mesh" id="resolution-control">
          <Grid container direction="column" spacing={2}>
            {objects.length > 1 && (
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Label title="Model">
                      This GCODE file contains multiple models, select the one
                      you want to reconstruct. Note that the result is always an
                      STL file, even if the original model below is a .step file
                      or some other type.
                    </Label>
                  </Grid>
                  <Grid item className={classes.selectContainer}>
                    <Select
                      id="gcode-object-select"
                      value={selectedObject}
                      fullWidth={true}
                      onChange={handleObjectChange}
                    >
                      {objects.map((o) => (
                        <MenuItem value={o.uid} key={o.uid}>
                          {o.copies > 1 ? `${o.name} (ID: ${o.id})` : o.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Label title="Resolution">
                    Low values produce better results, but require more
                    resources. Use higher values for large models or you may hit
                    the resource limit.
                  </Label>
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        id="standard-start-adornment"
                        fullWidth
                        value={resolution}
                        onChange={handleResolutionChange}
                        onBlur={handleResolutionBlur}
                        inputProps={{
                          step: RESOLUTION.step,
                          min: RESOLUTION.min,
                          max: RESOLUTION.max,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mm</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} className={classes.sliderContainer}>
                      <Slider
                        ref={resolutionRef}
                        value={resolution}
                        onChange={handleResolutionChange}
                        aria-labelledby="resolution-slider-label"
                        min={RESOLUTION.min}
                        max={RESOLUTION.max}
                        step={RESOLUTION.step}
                        color={resolutionExceeded ? 'secondary' : 'primary'}
                        track="inverted"
                        marks={[
                          {
                            value: 0.1,
                            label: 'detailed',
                          },
                          {
                            value: 0.55,
                            label: 'normal',
                          },
                          {
                            value: 1,
                            label: 'rough',
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Label title="Simplify">
                The simplification step reduces the complexity and size of the
                final model. The value determines what percentage of the edges
                of the polygons will remain. Simplifying the mesh does not
                reduce the detail of the resulting model. You can set it higher
                or disable it if this step takes a long time.
              </Label>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    id="standard-start-adornment"
                    value={maxEdges}
                    onChange={(e) => onMaxEdgesChange(e.target.value)}
                    onBlur={handleMaxEdgesBlur}
                    inputProps={{
                      step: MAX_EDGES.step,
                      min: MAX_EDGES.min,
                      max: MAX_EDGES.max,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={9} className={classes.sliderContainer}>
                  <Slider
                    value={maxEdges}
                    onChange={(e, v) => onMaxEdgesChange(v)}
                    aria-labelledby="simplify-slider-label"
                    track="inverted"
                    step={MAX_EDGES.step}
                    min={MAX_EDGES.min}
                    max={MAX_EDGES.max}
                    marks={[
                      {
                        value: 0,
                        label: 'auto',
                      },
                      {
                        value: 50,
                        label: 'remove half of the edges',
                      },
                      {
                        value: 100,
                        label: 'off',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ControlItem>

        <ControlItem label="GCODE" id="resolution-control">
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Grid container alignItems="center" spacing={0}>
                <Grid item xs={3}>
                  <Label title="Optimize">
                    Preprocess each layer and remove inner extrusions to
                    decrease resources usage. May introduce artifacts.
                  </Label>
                </Grid>
                <Grid item xs={9}>
                  <Switch
                    classes={{ root: classes.switch }}
                    aria-labelledby="optimize-layers-control"
                    checked={optimizeLayers}
                    onChange={(e, v) => onOptimizeLayersChange(v)}
                    name="checkedB"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Label title="Skip">
                If the resulting model contains a brim, use this option to
                remove bottom layers. Should be 0 in normal cases.
              </Label>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    value={startLayer}
                    onChange={(e) => onStartLayerChange(e.target.value)}
                    inputProps={{ step: '1', min: '0', max: numLayers - 1 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">layers</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={9} className={classes.sliderContainer}>
                  <Slider
                    value={startLayer}
                    onChange={(e, v) => onStartLayerChange(v)}
                    max={numLayers - 1}
                    marks={[
                      {
                        value: 0,
                        label: 0,
                      },
                      {
                        value: numLayers - 1,
                        label: numLayers - 1,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ControlItem>
      </Grid>
    </Grid>
  );
}

ReconstructionControls.propTypes = {
  resolution: PropTypes.number.isRequired,
  maxEdges: PropTypes.number.isRequired,
  optimizeLayers: PropTypes.bool.isRequired,
  startLayer: PropTypes.number,
  numLayers: PropTypes.number,
  selectedObject: PropTypes.string,
  objects: PropTypes.arrayOf(PropTypes.object),
  resolutionExceeded: PropTypes.bool,
  onResolutionChange: PropTypes.func,
  onMaxEdgesChange: PropTypes.func,
  onOptimizeLayersChange: PropTypes.func,
  onStartLayerChange: PropTypes.func,
  onSelectObject: PropTypes.func,
};

ReconstructionControls.defaultProps = {
  objects: [],
};

export default observer(ReconstructionControls);

import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import { useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import { useIsVisible } from 'react-is-visible';
import env from '../../utils/env';

let View = null;
let GeometryRepresentation = null;
let Reader = null;

if (env.isClient) {
  const rvj = require('react-vtk-js');
  View = rvj.View;
  GeometryRepresentation = rvj.GeometryRepresentation;
  Reader = rvj.Reader;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.preview,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressBar: {
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '10%',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: alpha(theme.palette.background.preview, 0.95),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnailContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
}));

const interactorSettings = [
  {
    button: 1,
    action: 'Rotate',
  },
  {
    button: 2,
    action: 'Pan',
  },
  {
    button: 3,
    action: 'Zoom',
    scrollEnabled: true,
  },
  {
    button: 1,
    action: 'Pan',
    alt: true,
  },
  {
    button: 1,
    action: 'Zoom',
    control: true,
  },
  {
    button: 1,
    action: 'Roll',
    shift: true,
  },
];

function ReconstructionPreview({
  id,
  className,
  label,
  url,
  interactive,
  showEdges,
  parallelProjection,
  overlay,
  thumbnailURL,
  rotate,
  zoom,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [progress, setProgress] = useState(100);
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const showPlaceholder =
    (!env.isClient || (!url && !thumbnailURL)) && !overlay;

  const progressCallback = useCallback(
    (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const percent = Math.floor(
          (100 * progressEvent.loaded) / progressEvent.total,
        );
        setProgress(percent);
      }
    },
    [url],
  );

  return (
    <div className={clsx(classes.root, className)} ref={nodeRef}>
      {showPlaceholder ? (
        <Typography variant="overline">{label}</Typography>
      ) : (
        <>
          {!thumbnailURL ? (
            isVisible ? (
              <View
                background={theme.palette.stl.previewBackground}
                interactive={interactive}
                interactorSettings={interactorSettings}
                cameraParallelProjection={parallelProjection}
                initialZoom={zoom}
              >
                <GeometryRepresentation
                  id={id}
                  property={{
                    color: theme.palette.stl.color,
                    // ambientColor: [1, 0, 0],
                    // diffuseColor: [1, 0, 0],
                    // specularColor: [1, 0, 0],
                    edgeColor: theme.palette.stl.edge,
                    edgeVisibility: showEdges,
                  }}
                  colorMapPreset="rainbow"
                  mapper={{ scalarVisibility: false }}
                  rotate={rotate}
                >
                  <Reader
                    vtkClass="vtkSTLReader"
                    url={url}
                    options={{
                      binary: true,
                      progressCallback,
                    }}
                  />
                </GeometryRepresentation>
              </View>
            ) : (
              <Typography variant="overline">Loading</Typography>
            )
          ) : (
            <div
              className={classes.thumbnailContainer}
              style={{
                backgroundImage: `url('${thumbnailURL}')`,
              }}
            ></div>
          )}
          {progress < 100 && (
            <div className={classes.progressBar}>
              <LinearProgress
                variant={progress < 100 ? 'determinate' : 'indeterminate'}
                value={progress}
              />
            </div>
          )}
          {overlay && <div className={classes.overlay}>{overlay}</div>}
        </>
      )}
    </div>
  );
}

ReconstructionPreview.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  interactive: PropTypes.bool,
  showEdges: PropTypes.bool,
  parallelProjection: PropTypes.bool,
  overlay: PropTypes.node,
  thumbnailURL: PropTypes.string,
  rotate: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
};

ReconstructionPreview.defaultProps = {
  className: '',
  label: 'No preview',
  url: null,
  interactive: true,
  showEdges: false,
  parallelProjection: false,
  overlay: null,
  thumbnailURL: null,
  rotate: [-45, 0, 45],
  zoom: 1.6,
};

export default observer(ReconstructionPreview);

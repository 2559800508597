import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Logo from '../Logo';
import clsx from 'clsx';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  logoContainer: {
    width: '100%',
  },
  logoText: {
    fontSize: 18,
  },
  logoTextLarge: {
    fontSize: 38,
  },
  logoTextBeta: {
    fontSize: 8,
    position: 'absolute',
    right: 0,
    top: -6,
  },
}));

function LogoWordmark({ large }) {
  const classes = useStyles();
  const logoSize = large ? 160 : 36;

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      flexDirection={large ? 'column-reverse' : 'row'}
      className={classes.logoContainer}
    >
      <Box pr={large ? 0 : 1} style={{ height: logoSize }}>
        <Logo width={logoSize} height={logoSize} />
      </Box>
      <Box position="relative">
        <Typography
          className={clsx({
            [classes.logoText]: !large,
            [classes.logoTextLarge]: large,
          })}
          color={'textPrimary'}
        >
          gcode2l
        </Typography>
        <Typography className={classes.logoTextBeta} color={'textSecondary'}>
          beta
        </Typography>
      </Box>
    </Box>
  );
}

LogoWordmark.propTypes = {
  large: PropTypes.bool,
};

LogoWordmark.defaultProps = {
  large: false,
};

export default observer(LogoWordmark);

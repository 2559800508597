import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import * as EmailValidator from 'email-validator';
import { useStore } from '../../store';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: 320,
    width: 500,
  },
  emailFormGrid: {
    height: '100%',
  },
}));

function EmailLoginDialog({ open, migrate, onClose, onGoogleLogin }) {
  const classes = useStyles();
  const store = useStore();
  const { auth } = store;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState(false);
  const [emailFieldError, setEmailFieldError] = useState(false);
  const [passwordFieldError, setPasswordFieldError] = useState(false);
  const [verifyPasswordFieldError, setVerifyPasswordFieldError] = useState(
    false,
  );

  const handleEmailDialogClose = () => {
    if (!registerEmail) {
      onClose();
    }
    setRegisterEmail(false);

    setEmailFieldError(false);
    setPasswordFieldError(false);
    setVerifyPasswordFieldError(false);
  };

  const handleRegisterEmail = () => {
    setRegisterEmail(true);
  };

  const handleEmailSkipToGmail = () => {
    onClose();
    onGoogleLogin();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleVerifyPasswordChange = (e) => {
    setVerifyPassword(e.target.value);
  };

  const validateForm = () => {
    let valid = true;

    if (!EmailValidator.validate(email)) {
      setEmailFieldError('Email address is not valid');
      valid = false;
    } else if (emailFieldError) {
      setEmailFieldError(false);
    }

    if (registerEmail) {
      if (password.length < 6) {
        setPasswordFieldError('Password should be at least 6 characters');
        valid = false;
      } else if (passwordFieldError) {
        setPasswordFieldError(false);
      }

      if (verifyPassword !== password) {
        setVerifyPasswordFieldError(`Passwords doesn't match`);
        valid = false;
      } else if (verifyPasswordFieldError) {
        setVerifyPasswordFieldError(false);
      }
    }
    return valid;
  };

  const handleSignInWithEmail = async () => {
    const args = { email, password, redirect: true };
    if (registerEmail) {
      if (validateForm()) {
        store.setBackdrop(true);
        try {
          if (migrate) {
            args.redirect = false;
            await auth.registerAndMigrateToEmail(args);
          } else {
            await auth.registerEmail(args);
          }
          onClose();
        } catch (error) {
          if (error.code === 'auth/email-already-in-use') {
            setEmailFieldError(
              'This email is already in use, click "GO BACK" below and use Sign In form',
            );
          }
        }
        store.setBackdrop(false);
      }
    } else {
      if (validateForm()) {
        try {
          if (migrate) {
            await auth.migrateToEmail(args);
          } else {
            await auth.loginWithEmail(args);
          }
        } catch (error) {
          if (error.code === 'auth/user-not-found') {
            setEmailFieldError('The email is not registered');
          } else if (error.code === 'auth/wrong-password') {
            setPasswordFieldError('The password is invalid');
          }
        }
      }
    }
  };

  const handleEmailInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignInWithEmail();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleEmailDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {registerEmail ? 'Sign Up' : 'Sign In'}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={classes.emailFormGrid}
        >
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  id="standard-email-input"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  fullWidth
                  variant="filled"
                  onChange={handleEmailChange}
                  onKeyDown={handleEmailInputKeyDown}
                  error={!!emailFieldError}
                  helperText={emailFieldError}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="standard-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  fullWidth
                  variant="filled"
                  onChange={handlePasswordChange}
                  onKeyDown={handleEmailInputKeyDown}
                  error={!!passwordFieldError}
                  helperText={passwordFieldError}
                />
              </Grid>
              {registerEmail && (
                <Grid item xs>
                  <TextField
                    id="standard-password-input"
                    label="Verify Password"
                    type="password"
                    autoComplete="verify-password"
                    fullWidth
                    variant="filled"
                    onChange={handleVerifyPasswordChange}
                    onKeyDown={handleEmailInputKeyDown}
                    error={!!verifyPasswordFieldError}
                    helperText={verifyPasswordFieldError}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {!registerEmail && (
            <Grid item>
              <Typography paragraph>
                New user? <a onClick={handleRegisterEmail}>Sign Up</a> with an
                email and password. If you have a Gmail account - click{' '}
                <a onClick={handleEmailSkipToGmail}>here</a> to skip the sign up
                step.
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEmailDialogClose}>
          {registerEmail ? 'Go Back' : 'Close'}
        </Button>
        <Button onClick={handleSignInWithEmail} color="primary" autoFocus>
          {registerEmail ? 'Create account' : 'Sign In With Email'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EmailLoginDialog.propTypes = {
  open: PropTypes.bool,
  migrate: PropTypes.bool,
  onClose: PropTypes.func,
  onGoogleLogin: PropTypes.func,
  // onEmailLogin: PropTypes.func,
  // onEmailRegister: PropTypes.func,
};

EmailLoginDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onGoogleLogin: () => {},
  // onEmailLogin: () => {},
  // onEmailRegister: () => {},
};

export default observer(EmailLoginDialog);

import React from 'react';
import { configure } from 'mobx';
import env from '../utils/env';

if (env.isClient) {
  configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    // reactionRequiresObservable: true,
    observableRequiresReaction: true,
    // disableErrorBoundaries: true,
  });
}

const StoreContext = React.createContext();

/* eslint-disable react/prop-types */
export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
/* eslint-enable react/prop-types */

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
/* eslint-disable react/display-name */
export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};
/* eslint-enable react/display-name */

export { default as Store } from './Store';
export { default as Cache } from './Cache';

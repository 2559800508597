import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { QRCode } from 'react-qrcode-logo';
import Settings from '../../components/Settings';
import { useStore } from '../../store';
import { LIMIT_MEMORY_GB, LIMIT_TIMEOUT_SEC } from '../../store/constants';
import ReconstructionPreview from '../../components/ReconstructionPreview';
import Rewards from '../../components/Rewards';

const useStyles = makeStyles((theme) => ({
  content: {
    height: 640,
  },
  fullWidthContent: {
    height: 640,
    padding: 0,
  },
  tabs: {
    marginTop: theme.spacing(4),
  },
  paper: {
    minWidth: '699px',
    padding: 0,
  },
  preview: {
    height: 130,
    width: 233,
    '& div div': {
      cursor: 'default !important',
    },
  },
  donateTabPanelRoot: {
    padding: 0,
  },
  donateText: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // overflow: 'scroll',
    // maxHeight: 448,
  },
  donateHeader: {
    backgroundColor: theme.palette.background.preview,
    display: 'flex',
    alignItems: 'center',
  },
  donateActionSection: {
    width: 233,
  },
  donateQrContainer: {
    width: 233,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Welcome() {
  return (
    <div>
      <Typography variant="h6">Welcome to gcode2l</Typography>
      <Typography paragraph>
        A tool for reconstructing an STL model from GCODE files for 3D printers.
        It is currently in beta and may not work with some file types.
      </Typography>
      <Typography paragraph>
        Note that there is no way to reconstruct original model from a GCODE
        file without a loss in quality. A GCODE file contains only an
        approximation of the original model.
      </Typography>
      <Typography paragraph>
        If you have troubles with the conversion, check the <b>Help</b> section
        and make sure you&apos;ve set reconstruction parameters to the correct
        values for your model. Hover over the yellow question mark next to a
        parameter to see how it affects the result.
      </Typography>
      <Typography variant="h6">Supported Slicers</Typography>
      <Typography variant="body1">
        The tool has been optimized for slicers listed below. It will also work
        with files from other slicers, but reconstruction will require more
        resources and may produce models with artifacts and supports.
      </Typography>
      <ul>
        <Typography component="li">Cura</Typography>
        <Typography component="li">PrusaSlicer</Typography>
        <Typography component="li">OrcaSlicer</Typography>
        <Typography component="li">SuperSlicer</Typography>
        <Typography component="li">Creality Print, Creality Slicer</Typography>
        <Typography component="li">Simplify3D *</Typography>
      </ul>
      <Typography variant="caption">
        * This slicer does not include meta information about the source models.
        For GCODE files with multiple models, you will not be able to select
        which model to reconstruct. Only the largest model in the set will be
        reconstructed.
      </Typography>
    </div>
  );
}

function Help() {
  return (
    <div>
      <Typography variant="h6">Convert</Typography>
      <Typography paragraph>
        After you&apos;ve uploaded the files, click the file card, then in the
        dialog that opens, select <b>New</b> on the left, adjust options and
        click <b>Reconstruct</b>. After reconstruction process is complete,
        click <b>Download STL</b> on the right side of the dialog. The{' '}
        <b>Download STL</b> link on the file card is a shortcut to download
        latest STL file generated.
      </Typography>

      <Typography variant="h6">Parameters</Typography>
      <Typography paragraph>
        The reconstruction parameters allow you to get finer details for small
        models, while being able to fit into resource limit for large models.
        There is no correct combination of parameters for every file. For
        detailed information on a specific parameter hover over the <b>?</b>{' '}
        icon next to the corresponding parameter.
      </Typography>

      <Typography variant="h6">Extrusion Tags</Typography>
      <Typography paragraph>
        You may see <b>No extrusion tags</b> warning in the GCODE details
        section. This means that the file does not contain a special type of
        comments that indicates which extrusion type each line belongs to.
      </Typography>
      <Typography paragraph>
        Missing extrusion tags can cause the model to contain brim and supports.
        Without tags, the reconstruction process will also use more resources.
      </Typography>

      <Typography variant="h6">Detecting Issues</Typography>
      <Typography paragraph>
        After you have reconstructed your model and imported it into a slicer to
        print with a different settings, you may find that the model is not
        printable. This may be caused by holes, inverted polygons or other
        artifacts. To detect such problems in gcode2l, enable the{' '}
        <b>Show Edges</b> option in the model preview dialog. This shows how the
        mesh is constructed - in normal cases it is mostly uniform. If you spot
        a burst of triangles in one spot, this could indicate a problem.
      </Typography>

      <Typography variant="h6">Resolving Issues</Typography>
      <ul>
        <Typography component="li">
          Artifacts - adjust <b>Resolution</b> and/or <b>Optimize</b> option
        </Typography>
        <Typography component="li">
          Skirt - try to set lower <b>Resolution</b> value(more details)
        </Typography>
        <Typography component="li">
          Brim - use <b>Skip</b> option to cut off the first layer
        </Typography>
      </ul>
    </div>
  );
}

function Donate({ amount, onOpenBmc }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <div className={classes.donateHeader}>
        <ReconstructionPreview
          className={classes.preview}
          url={'/cup.stl'}
          showEdges={true}
          interactive={false}
          rotate={[-60, 0, 0]}
          zoom={1.33}
        />
        <Box
          className={classes.donateActionSection}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={onOpenBmc}
            variant="outlined"
            color="primary"
            href="https://www.buymeacoffee.com/gcode2l"
            target="_blank"
            endIcon={<OpenInNewIcon />}
          >
            Buy Me A Coffee
          </Button>
        </Box>
        <div className={classes.donateQrContainer}>
          <QRCode
            bgColor={theme.palette.background.preview}
            fgColor={theme.palette.text.primary}
            value="https://www.buymeacoffee.com/gcode2l"
            qrStyle="dots"
            size={100}
            logoHeight={31}
            logoWidth={18}
            logoImage={theme.logo.bmc}
            removeQrCodeBehindLogo={true}
          />
        </div>
      </div>
      <div className={classes.donateText}>
        <Typography variant="h6">Why Donate?</Typography>
        <Typography paragraph align="justify">
          The model reconstruction process is resource intensive, and since the
          number of users has increased recently, I cannot afford to pay the
          cloud service bill on my own.
        </Typography>
        <Typography paragraph align="justify">
          By donating, you&apos;re helping me keep gcode2l online, and not
          hiding it behind a paywall or completely kill the project, as has
          happened with other similar tools.
        </Typography>
        <Typography paragraph align="justify">
          Your donation is also an incentive for me to dedicate more time to the
          project and make it better, while keeping it free of charge for users
          who cannot afford it.
        </Typography>

        <Typography variant="h6">Extend Resource Limits</Typography>
        <Typography paragraph align="justify">
          You can increase the default limits of {LIMIT_MEMORY_GB}GB of memory
          and {parseInt(LIMIT_TIMEOUT_SEC / 60)} minutes timeout for a
          reconstruction instance by donating to the project.
        </Typography>
        <Box pl={2} pr={2} pt={4}>
          <Rewards amount={amount} />
        </Box>
        <Typography paragraph align="justify">
          Extended limits are permanent, you donate once, and you can run
          unlimited number of reconstructions. The only limitation is that you
          can run one reconstruction with extended limits at a time. If
          you&apos;ll run two or more reconstructions in parallel, the extended
          limits will only apply to the first one.
        </Typography>
        <Typography paragraph align="justify">
          Please note, you&apos;re not buying, you&apos;re donating, and
          I&apos;m providing you with access to a more powerful server as a
          thank you for supporting the project. There are no guarantees
          regarding the results or availability of the servers.
        </Typography>
        <Typography paragraph align="justify">
          The limits will be increased automatically after donation, just make
          sure you are using the same email address on both gcode2l and Buy Me A
          Coffee. Donations are cumulative and you can gradually extend the
          limits by donating multiple times.
        </Typography>
        <Typography paragraph align="justify">
          If you have any issues with gcode2l, send me a message on Buy Me A
          Coffee.
        </Typography>
      </div>
    </div>
  );
}

Donate.propTypes = {
  onOpenBmc: PropTypes.func,
};

Donate.defaultProps = {
  onOpenBmc: () => {},
};

let lastSelectedTab = 'welcome';
function InfoDialog({ open, onClose, onSelect }) {
  const classes = useStyles();
  const { user } = useStore();

  if (open) {
    lastSelectedTab = open;
  }

  const handleChange = (event, newValue) => {
    onSelect(newValue);
  };

  return (
    <Dialog
      open={
        open === 'welcome' ||
        open === 'help' ||
        open === 'account' ||
        open === 'donate'
      }
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.paper,
      }}
      // fullWidth
      // maxWidth="sm"
    >
      <TabContext value={open || lastSelectedTab}>
        <TabList
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          centered
          // variant="fullWidth"
        >
          <Tab label="Welcome" value="welcome" />
          <Tab label="Help" value="help" />
          <Tab label="Account" value="account" />
          <Tab label="Donate" value="donate" />
        </TabList>
        <DialogContent
          className={
            open === 'donate' ? classes.fullWidthContent : classes.content
          }
        >
          <TabPanel value="welcome">
            <Welcome />
          </TabPanel>
          <TabPanel value="help">
            <Help />
          </TabPanel>
          <TabPanel value="account">
            <Settings />
          </TabPanel>
          <TabPanel
            value="donate"
            classes={{ root: classes.donateTabPanelRoot }}
          >
            <Donate amount={user.donationsTotal} onOpenBmc={user.openBmc} />
          </TabPanel>
        </DialogContent>
      </TabContext>
      <DialogActions
        disableSpacing={true}
        classes={{ root: classes.dialogActions }}
      >
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

InfoDialog.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

InfoDialog.defaultProps = {
  open: false,
};

export default observer(InfoDialog);

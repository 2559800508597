import { observable, computed, runInAction } from 'mobx';
import { Collection, Document } from 'firestorter';
import moment from 'moment';

class Supporter extends Document {
  _options = null;

  constructor(source, options) {
    super(source, options);
    this._options = options;
  }

  get donationsCollection() {
    if (this._donationsCollection) return this._donationsCollection;

    this._donationsCollection = new Collection(() => `${this.path}/donations`);

    return this._donationsCollection;
  }

  @computed get donations() {
    return this.donationsCollection.docs;
  }

  @computed get donationsTotal() {
    if (!this.hasData) {
      return 0;
    }
    return this.data.totalAmount;
  }

  @computed get latestDonation() {
    const { latestDonation } = this.data;
    if (!this.hasData || !latestDonation) {
      return 'Never';
    }
    const mdate = moment(latestDonation.toDate());
    return `${mdate.fromNow()} (${mdate.format()})`;
  }

  @computed get donationsSortedByDate() {
    return this.donations
      .slice()
      .sort(
        (a, b) =>
          new Date(b.data.support_created_on) -
          new Date(a.data.support_created_on),
      );
  }
}

export default Supporter;

import { action, computed, flow, observable, runInAction, toJS } from 'mobx';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import UniversalCollection from './UniversalCollection';
import UniversalDocument from './UniversalDocument';
import { Collection } from 'firestorter';
import Model from './Model';
import {
  RESOLUTION,
  MAX_EDGES,
  LIMIT_MEMORY_GB,
  LIMIT_TIMEOUT_SEC,
} from './constants';
import env from '../utils/env';

class User extends UniversalDocument {
  constructor(source, options) {
    super(source, { ...options, autoCreate: true /*, mode: 'on' */ });
  }

  ready() {
    return Promise.all([
      super.ready(),
      this.modelsCollection.ready(),
      ...this.models.map((p) => p.ready()),
    ]);
  }

  get modelsCollection() {
    if (this._modelsCollection) return this._modelsCollection;

    this._modelsCollection = new UniversalCollection(
      () => `${this.path}/models`,
      {
        ...this.options,
        createDocument: (s, o) =>
          new Model(s, {
            ...this.options,
            ...o,
            autoCreate: false,
          }),
      },
    );
    return this._modelsCollection;
  }

  get supporter() {
    if (this._supporter) return this._supporter;

    this._supporter = new UniversalDocument(
      () => `/supporter/${this.id}`,
      this._options,
    );

    return this._supporter;
  }

  get limits() {
    if (this._limits) return this._limits;

    this._limits = new UniversalDocument(
      () => `/userLimits/${this.id}`,
      this._options,
    );

    return this._limits;
  }

  get donationsCollection() {
    if (this._donationsCollection) return this._donationsCollection;

    this._donationsCollection = new Collection(
      `/supporter/${this.id}/donations`,
    );
    return this._donationsCollection;
  }

  @computed get models() {
    return this.modelsCollection.docs;
  }

  @computed get modelsSortedByDate() {
    return this.models.slice().sort((a, b) => b.createdAt - a.createdAt);
  }

  get opsCollection() {
    if (this._opsCollection) return this._opsCollection;

    this._opsCollection = new UniversalCollection(() => `${this.path}/ops`, {
      ...this.options,
      createDocument: (s, o) =>
        new Model(s, {
          ...this.options,
          ...o,
          autoCreate: false,
        }),
    });
    return this._opsCollection;
  }

  @computed get isInitialized() {
    const { initialized } = this.data;

    return initialized;
  }

  @computed get theme() {
    const { theme } = this.data;
    return typeof theme === 'string' ? theme : null;
  }

  @computed get isAutoTheme() {
    const { autoTheme } = this.data;
    return typeof autoTheme === 'boolean' ? autoTheme : true;
  }

  @computed get resolution() {
    return this.getLocal('resolution', RESOLUTION.default);
  }

  @computed get resolutionExceeded() {
    return !this.extendedLimits && this.resolution < 0.55;
  }

  @computed get optimizeLayers() {
    return this.getLocal('optimizeLayers', true);
  }

  @computed get maxEdges() {
    const val = this.getLocal('maxEdges', MAX_EDGES.defaultFloat);
    return parseInt(val * 100);
  }

  @computed get showEdges() {
    return this.getLocal('showEdges', false);
  }

  @computed get infoOpen() {
    return this.getLocal('infoOpen', false);
  }

  @computed get parallelProjection() {
    return this.getLocal('parallelProjection', false);
  }

  @computed get selectedModel() {
    const id = this.getLocal('selectedModel', null);
    if (id === null) {
      return null;
    }
    return this.models.find((m) => m.id === id);
  }

  @computed get dialog() {
    return this.getLocal('dialog', false);
  }

  @computed get hasRunningOps() {
    return this.opsCollection.hasDocs;
  }

  @computed get hasRunningRecs() {
    return this.models.reduce(
      (acc, v) => acc || v.hasActiveReconstructions,
      false,
    );
  }

  @computed get canReconstruct() {
    return (
      (!this.options.store.auth.isAnon || !this.hasRunningRecs) &&
      !this.resolutionExceeded
    );
  }

  @computed get cantReconstructReason() {
    if (this.options.store.auth.isAnon && this.hasRunningRecs) {
      return 'Anonymous users can run only one reconstruction at a time. Sign in or wait for the ongoing reconstruction to finish.';
    }
    if (this.resolutionExceeded) {
      return 'Set resolution above or equal to 0.55mm';
    }

    return '';
  }

  @computed get donationsTotal() {
    if (!this.supporter.hasData) {
      return 0;
    }
    return this.supporter.data.totalAmount;
  }

  @computed get latestDonation() {
    const { latestDonation } = this.supporter.data;
    if (!this.supporter.hasData || !latestDonation) {
      return 'Never';
    }
    const mdate = moment(latestDonation.toDate());
    return `${mdate.fromNow()} (${mdate.format()})`;
  }

  @computed get extendedLimits() {
    if (!this.limits.hasData) {
      return false;
    }
    const { memory, timeout } = this.limits.data;
    return memory > LIMIT_MEMORY_GB || timeout > LIMIT_TIMEOUT_SEC;
  }

  @computed get memoryLimit() {
    if (!this.extendedLimits) {
      return LIMIT_MEMORY_GB;
    }

    return this.limits.data.memory;
  }

  @computed get timeoutLimit() {
    if (!this.extendedLimits) {
      return LIMIT_TIMEOUT_SEC;
    }

    return this.limits.data.timeout;
  }

  @computed get isLimitsManual() {
    if (!this.extendedLimits) {
      return false;
    }

    return Boolean(this.limits.data.manual);
  }

  @computed get donations() {
    return this.donationsCollection.docs;
  }

  @action setTheme(type) {
    if (type !== 'dark' && type !== 'light') {
      throw new Error(`'type' should be 'dark' or 'light', got ${type}`);
    }
    this.update({ theme: type });
  }

  @action setAutoTheme(enabled) {
    if (typeof enabled !== 'boolean') {
      throw new Error(`Expected 'enabled' to be boolean`);
    }
    this.update({ autoTheme: enabled });
  }

  @action deleteModel(model) {
    return model.delete();
  }

  addModel = flow(function* (name, file) {
    const model = yield this.modelsCollection.add({
      filename: name,
      createdAt: new Date(),
      processed: false,
      fileSize: file.size,
    });

    yield model.uploadFile(file);
  });

  @action uploadFiles(files) {
    if (files.length === 0) {
      return;
    }
    files = files.map ? files : [files[0]];

    const event = {
      number: files.length,
      items: files.map((f) => ({
        size: prettyBytes(f.size),
        sizeBytes: f.size,
      })),
    };
    if (env.isProduction) {
      firebase.analytics().logEvent('upload_files', event);
    }

    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.addModel(file.name, file);
    }
  }

  addReconstruction = flow(function* (model) {
    const options = {
      resolution: this.resolution,
      maxEdges: this.maxEdges / 100,
      optimizeLayers: this.optimizeLayers,
      startLayer: model.startLayer,
      object: toJS(model.selectedObject),
    };

    const rec = yield model.addReconstruction(options);
    if (env.isProduction) {
      firebase.analytics().logEvent('reconstruction', options);
    }

    return rec;
  });

  @action setInitialized(val) {
    this.prudentUpdate({ initialized: val });
  }

  @action setResolution(val) {
    this.prudentUpdate({ resolution: val });
  }

  @action setOptimizeLayers(enabled) {
    this.prudentUpdate({ optimizeLayers: enabled });
  }

  @action setMaxEdges(value) {
    const v = value / 100;
    this.prudentUpdate({ maxEdges: v });
  }

  @action setShowEdges(enabled) {
    this.prudentUpdate({ showEdges: enabled });
  }

  @action setParallelProjection(enabled) {
    this.prudentUpdate({ parallelProjection: enabled });
  }

  @action setInfoOpen(open) {
    this.prudentUpdate({ infoOpen: open });
  }

  @action setSelectedModel(model) {
    const id = model ? model.id : null;
    this.prudentUpdate({ selectedModel: id });
  }

  @action setDialog(key) {
    this.prudentUpdate({ dialog: key });

    if (key === 'donate' && env.isProduction) {
      firebase.analytics().logEvent('view_donate');
    }
  }

  @action openBmc() {
    if (env.isProduction) {
      firebase.analytics().logEvent('open_bmc');
    }
  }

  @action async removeAllRecs() {
    for (const m of this.models) {
      for (const r of m.recs) {
        try {
          await r.delete();
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  @action importTestSet() {
    this.opsCollection.add({ op: 'importTestSet' });
  }

  @action claimRewards() {
    this.opsCollection.add({ op: 'claimRewards' });
  }

  @action setLimits(memory, timeout, manual) {
    this.limits.set({ memory, timeout, manual });
  }
}

export default User;

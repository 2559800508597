import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useStore } from '../../store';
import GoogleSignInButton from '../../components/GoogleSignInButton';
import SigninCopy from '../../components/SigninCopy';
import Logo from '../../components/Logo';
import EmailLoginDialog from '../../components/EmailLoginDialog/EmailLoginDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  intro: {
    maxWidth: 1000,
  },
  loginContainer: {
    maxWidth: 600,
  },
}));

export function Login() {
  const classes = useStyles();
  const store = useStore();
  const { auth } = store;
  const [emailDialog, setEmailDialog] = useState(false);

  const handleGoogleLogin = async () => {
    store.setBackdrop(true);
    try {
      await auth.loginWithGoogle({ redirect: true });
    } catch (error) {
      console.log(error.message);
    }
    store.setBackdrop(false);
  };

  const handleAnonymousLogin = async () => {
    store.setBackdrop(true);
    try {
      await auth.loginAnon({ redirect: true });
    } catch (error) {
      console.log(error.message);
    }
    store.setBackdrop(false);
  };

  const handleEmailDialogOpen = () => {
    setEmailDialog(true);
  };

  const handleEmailDialogClose = () => {
    setEmailDialog(false);
  };

  const logoSize = 450;

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.intro}
        alignItems="center"
        justifyContent="center"
        direction="row"
        spacing={0}
      >
        <Grid item xs={12} md={6}>
          <Box style={{ opacity: 0.1 }}>
            <Logo width={logoSize} height={logoSize} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            direction="column"
            spacing={4}
            className={classes.loginContainer}
          >
            <Grid item>
              <Typography variant="h3" gutterBottom>
                gcode2l
              </Typography>
              <Typography variant="body1" gutterBottom>
                Convert GCODE file sliced for an FDM 3D printer to STL model.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                Sign in to continue
              </Typography>
              <SigninCopy />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                // justifyContent="space-between"
                spacing={6}
              >
                <Grid item xs>
                  <GoogleSignInButton
                    fullWidth
                    size="large"
                    onClick={handleGoogleLogin}
                    color="primary"
                  />
                </Grid>

                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      <Button
                        fullWidth
                        size="large"
                        onClick={handleEmailDialogOpen}
                        color="primary"
                        // variant="outlined"
                      >
                        Sign in with Email
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        fullWidth
                        size="large"
                        onClick={handleAnonymousLogin}
                        // variant="outlined"
                        // color="primary"
                      >
                        Skip for now
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EmailLoginDialog
        open={emailDialog}
        onClose={handleEmailDialogClose}
        onGoogleLogin={handleGoogleLogin}
      />
    </div>
  );
}

export default observer(Login);

import { computed, observable, action, flow, autorun } from 'mobx';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';
import { isServer } from '../utils/env';
import UniversalDocument from './UniversalDocument';
import {
  EDGE_COST_LIMIT,
  LIMIT_MEMORY_GB,
  LIMIT_TIMEOUT_SEC,
  RESOLUTION,
  MAX_EDGES,
} from './constants';
import env from '../utils/env';

class Reconstruction extends UniversalDocument {
  _disposeAutorun = null;
  @observable accessor _fileDownloadURL = null;

  constructor(source, options) {
    super(source, options);

    if (!isServer()) {
      this._disposeAutorun = autorun(() => {
        if (this.isReady && this._fileDownloadURL === null && !this.fromCache) {
          this.updateDownloadURL();
        }
      });
    }
  }

  dispose() {
    this._disposeAutorun();
    this._disposeAutorun = null;
  }

  async delete() {
    if (this._disposeAutorun) {
      this._disposeAutorun();
      this._disposeAutorun = null;
    }
    try {
      await this.deleteFile();
    } catch (e) {
      //
    }
    return super.delete();
  }

  deleteFile() {
    return this.fileRef.delete();
  }

  @computed get fileRef() {
    const storage = firebase.storage();
    return storage.ref(`${this.path}.stl`);
  }

  @computed get fileDownloadURL() {
    return this._fileDownloadURL;
  }

  @computed get fileSizeBytes() {
    return typeof this.data.fileSize === 'number' ? this.data.fileSize : 0;
  }

  @computed get fileSize() {
    return prettyBytes(this.fileSizeBytes);
  }

  @computed get resolution() {
    return this.getLocal('resolution', RESOLUTION.default);
  }

  @computed get optimizeLayers() {
    return this.getLocal('optimizeLayers', true);
  }

  @computed get maxEdges() {
    const val = this.getLocal('maxEdges', MAX_EDGES.defaultFloat);
    return parseInt(val * 100);
  }

  @computed get createdAt() {
    const { createdAt } = this.data;
    return new Date(createdAt ? createdAt.seconds * 1000 : 0);
  }

  @computed get processedAt() {
    const { processedAt } = this.data;
    return new Date(processedAt ? processedAt.seconds * 1000 : 0);
  }

  @computed get startAt() {
    const { startAt } = this.data;
    return new Date(startAt ? startAt.seconds * 1000 : 0);
  }

  @computed get startLayer() {
    return this.data.startLayer || 0;
  }

  @computed get edgesRatio() {
    return this.data.edgesRatio || 1;
  }

  @computed get edgesCost() {
    return this.data.edgesCost || 0;
  }

  @computed get simplificationProgress() {
    return ((1 - this.edgesRatio) / (1 - this.data.maxEdges)) * 100;
  }

  @computed get simplificationCost() {
    return (this.edgesCost / EDGE_COST_LIMIT) * 100;
  }

  @computed get reconstructed() {
    return this.data.reconstructed || false;
  }

  @computed get isReady() {
    return this.data.processed && !this.isFailed && this.fileSizeBytes > 0;
  }

  @computed get processedLayers() {
    return this.data.layersProcessed || 0;
  }

  @computed get numLayers() {
    return this.data.numLayers || 0;
  }

  @computed get sizeOrStatus() {
    if (this.fileSizeBytes > 0) return this.fileSize;
    if (this.isFailed) return 'failed';
    return 'in progress';
  }

  @computed get usedMemoryBytes() {
    return this.data.usedMemory || 0;
  }

  @computed get usedMemoryString() {
    return prettyBytes(this.usedMemoryBytes);
  }

  @computed get usedMemoryPercentage() {
    return (this.usedMemoryBytes / this.slotMemoryBytes) * 100;
  }

  @computed get isFailed() {
    return Boolean(this.data.error);
    // const { createdAt } = this.data;

    // if (!this.isReady) {
    //   return moment.unix(createdAt.seconds + 540).isBefore(moment());
    // }

    // return false;
  }

  @computed get error() {
    return this.data.error;
  }

  @computed get slotTimeout() {
    return this.data.slotTimeout || 540;
  }

  @computed get slotTimeoutString() {
    return moment.duration(this.slotTimeout, 'seconds').humanize(false);
  }

  @computed get slotMemory() {
    return this.data.slotMemory || LIMIT_MEMORY_GB;
  }

  @computed get slotMemoryBytes() {
    return this.slotMemory * 1000000000;
  }

  @computed get slotMemoryString() {
    return prettyBytes(this.slotMemoryBytes);
  }

  @computed get isLimitsExtended() {
    return (
      this.slotMemory > LIMIT_MEMORY_GB || this.slotTimeout > LIMIT_TIMEOUT_SEC
    );
  }

  @computed get reconstrutionTime() {
    const { startAt, processedAt } = this;
    const diff = moment(processedAt).diff(startAt);
    return diff > 0
      ? moment.duration(diff, 'milliseconds').humanize({ ss: 1 })
      : '0s';
  }

  @computed get isUnderConstruction() {
    return this.hasData && !this.isFailed && !this.isReady;
  }

  @computed get object() {
    return this.data.object;
  }

  @computed get objectNameAndId() {
    if (!this.hasObject) return 'STL';

    return this.object.copies > 1
      ? `${this.object.name} (ID: ${this.object.id})`
      : this.object.name;
  }

  @computed get hasObject() {
    return Boolean(this.object);
  }

  @action setResolution(val) {
    this.prudentUpdate({ resolution: val });
  }

  @action setOptimizeLayers(enabled) {
    this.prudentUpdate({ optimizeLayers: enabled });
  }

  @action setMaxEdges(value) {
    const v = value / 100;
    this.prudentUpdate({ maxEdges: v });
  }

  @action setDownloadURL(v) {
    this._fileDownloadURL = v;
  }

  updateDownloadURL = flow(function* () {
    const url = yield this.fileRef.getDownloadURL();
    this.setDownloadURL(url);
    // console.log('updating download url', this._fileDownloadURL);
  });

  @action downloadFile(location = 'main') {
    const params = {
      location,
      type: 'stl',
      size: this.fileSize,
      sizeBytes: this.fileSizeBytes,
    };
    window.open(this.fileDownloadURL, '_self');
    if (env.isProduction) {
      firebase.analytics().logEvent('download_file', params);
    }
  }
}

export default Reconstruction;

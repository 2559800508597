import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import ModelView from '../../components/ModelView';
import ReconstructionPreview from '../../components/ReconstructionPreview';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDropzone } from 'react-dropzone';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import GridOnIcon from '@material-ui/icons/GridOn';
import GridOffIcon from '@material-ui/icons/GridOff';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PerspectiveIcon from '@material-ui/icons/ViewCarousel';
import ParallelIcon from '@material-ui/icons/ViewArray';
import IconButton from '@material-ui/core/IconButton';
import Zoom from '@material-ui/core/Zoom';
import { truncate } from '../../utils/helpers';
import InfoDialog from '../../components/InfoDialog/InfoDialog';
import { BmcLogo } from '../../components/AppIcons';
import { ACCEPTED_FILES } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  mainGrid: {
    paddingBottom: theme.spacing(13),
  },
  card: {
    margin: '0 auto',
    position: 'relative',
  },
  preview: {
    height: 200,
  },
  plusCard: {
    backgroundColor: 'transparent',
  },
  plusCardContent: {
    height: 356,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  progressContainer: {
    height: 10,
  },
  actions: {
    padding: theme.spacing(1),
  },
  previewActions: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.preview,
  },
  dialogActions: {
    padding: 0,
  },
  checkbox: {
    padding: theme.spacing(1),
  },
  infoButton: {
    '& svg': {
      fontSize: '1.4rem',
    },
  },
  infoButtonSecondary: {
    '& svg': {
      color: theme.palette.text.secondary,
    },
  },
  placeholderContainer: {
    display: 'flex',
    flex: '1',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholder: {
    maxWidth: 500,
    minHeight: 500,
  },
}));

function Info() {
  const classes = useStyles();
  const store = useStore();
  const { user } = store;
  const model = user.selectedModel;
  const selectedRec = model && model.selectedRec;
  const dialog = user.dialog;
  const [showDonateIcon, setShowDonateIcon] = useState(false);

  const { getRootProps } = useDropzone({
    noClick: true,
    onDrop: (files) => user.uploadFiles(files),
    accept: ACCEPTED_FILES,
  });
  const { ...rootProps } = getRootProps();
  const recReady = selectedRec && selectedRec.isReady;

  const handleModelSelect = (m) => () => {
    user.setSelectedModel(m);
    user.setDialog('model');
  };

  const handleModelDelete = (m) => () => {
    m.delete();
  };

  const handleClose = () => {
    user.setDialog(false);
  };

  const handleDialogSelect = (dialog) => {
    user.setDialog(dialog);
  };

  const handleImport = () => {
    user.importTestSet();
  };

  const handleReconstruct = async () => {
    const rec = await user.addReconstruction(model);
    model.setSelectedRec(rec);
  };

  const handleDeleteReconstruction = async () => {
    if (selectedRec) {
      model.deleteReconstruction(selectedRec);
    }
  };

  const handleRecSelected = (rec) => {
    model.setSelectedRec(rec);
  };

  const handleShowEdgesChange = (e) => {
    user.setShowEdges(e.target.checked);
  };

  const handleParallelProjectionChange = (e) => {
    user.setParallelProjection(e.target.checked);
  };

  const handleInfoOpenChange = (e) => {
    user.setInfoOpen(e.target.checked);
  };

  const handleDonateClick = () => {
    user.setDialog('donate');
  };

  const handleDialogDownloadSTL = () => {
    setTimeout(() => selectedRec.downloadFile('dialog'), 500);
    setShowDonateIcon(true);
  };

  return (
    <div className={classes.root} {...rootProps}>
      {user.modelsSortedByDate.length === 0 && (
        <div className={classes.placeholderContainer}>
          <Grid
            container
            className={classes.placeholder}
            direction="column"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">No Files</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary">
                Click <b>+</b> at the bottom of the screen and select one or
                multiple files or drag them directly from a file manager
              </Typography>
            </Grid>
            <Grid item align="center">
              <Button color="primary" onClick={handleImport} fullWidth>
                Import Test Files Set
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      <Grid
        container
        direction="row"
        spacing={4}
        alignItems="flex-start"
        className={classes.mainGrid}
      >
        {user.modelsSortedByDate.map((m) => (
          <Grid item key={m.id} xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card className={classes.card}>
              <CardActionArea
                onClick={handleModelSelect(m)}
                disabled={!m.hasMeta}
              >
                <ReconstructionPreview
                  id={m.id}
                  className={classes.preview}
                  label={m.status}
                  url={m.previewURL}
                  interactive={false}
                  thumbnailURL={m.thumbnailURL}
                />
                <div className={classes.progressContainer}>
                  {m.isProcessing && (
                    <LinearProgress
                      variant={
                        m.progress < 100 ? 'determinate' : 'indeterminate'
                      }
                      value={m.progress}
                    />
                  )}
                </div>
                <CardContent>
                  <Typography variant="subtitle1" component="p" noWrap={true}>
                    {m.filename}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {truncate(m.slicer, 15)} ･ {m.fileSize} ･ {m.numLayers}{' '}
                    layers
                    <br />
                    {!m.latestRec
                      ? 'No STL files'
                      : `${m.numSTLFiles} STL file${
                          m.numSTLFiles !== 1 ? 's' : ''
                        }, latest - ${m.latestRec.sizeOrStatus}`}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  disabled={!m.latestRec || !m.latestRec.isReady}
                  size="small"
                  color="primary"
                  onClick={() =>
                    m.latestRec && m.latestRec.downloadFile('card')
                  }
                >
                  Download STL
                </Button>
                <Button size="small" onClick={handleModelDelete(m)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={dialog === 'model'}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        disableRestoreFocus
        disableScrollLock
        aria-labelledby="form-dialog-title"
      >
        <ModelView
          model={model}
          rec={selectedRec}
          overlay={user.infoOpen}
          resolution={user.resolution}
          maxEdges={user.maxEdges}
          optimizeLayers={user.optimizeLayers}
          showEdges={user.showEdges}
          parallelProjection={user.parallelProjection}
          userWithExtendedLimits={user.extendedLimits}
          resolutionExceeded={user.resolutionExceeded}
          onRecSelected={handleRecSelected}
          onResolutionChange={(v) => user.setResolution(v)}
          onMaxEdgesChange={(v) => user.setMaxEdges(v)}
          onOptimizeLayersChange={(v) => user.setOptimizeLayers(v)}
          onOpenDonate={() => user.setDialog('donate')}
        />
        <DialogActions
          disableSpacing={true}
          classes={{ root: classes.dialogActions }}
        >
          <Grid container>
            <Grid item xs={5}></Grid>
            <Grid
              item
              xs={7}
              className={recReady ? classes.previewActions : classes.actions}
            >
              <Grid container spacing={1} alignItems="center">
                {recReady && (
                  <Grid item>
                    <Tooltip title="Show Edges" arrow placement="top">
                      <Checkbox
                        size="small"
                        icon={<GridOffIcon />}
                        checkedIcon={<GridOnIcon />}
                        checked={user.showEdges}
                        onChange={handleShowEdgesChange}
                        classes={{ root: classes.checkbox }}
                      />
                    </Tooltip>
                  </Grid>
                )}
                {recReady && (
                  <Grid item>
                    <Tooltip title="Parallel Projection" arrow placement="top">
                      <Checkbox
                        size="small"
                        icon={<PerspectiveIcon />}
                        checkedIcon={<ParallelIcon />}
                        checked={user.parallelProjection}
                        onChange={handleParallelProjectionChange}
                        classes={{ root: classes.checkbox }}
                      />
                    </Tooltip>
                  </Grid>
                )}
                {recReady && (
                  <Grid item className={classes.infoBtnContainer}>
                    <Tooltip title="Summary" arrow placement="top">
                      <Checkbox
                        size="small"
                        icon={<InfoIcon />}
                        checkedIcon={<InfoIcon />}
                        checked={user.infoOpen}
                        onChange={handleInfoOpenChange}
                        classes={{ root: classes.checkbox }}
                      />
                    </Tooltip>
                  </Grid>
                )}
                {recReady && (
                  <Grid item className={classes.infoBtnContainer}>
                    <Zoom in={showDonateIcon}>
                      <Tooltip title="Buy me a coffee!" arrow placement="top">
                        <IconButton
                          size="small"
                          classes={{ root: classes.checkbox }}
                          color="inherit"
                          onClick={handleDonateClick}
                        >
                          <BmcLogo />
                        </IconButton>
                      </Tooltip>
                    </Zoom>
                  </Grid>
                )}
                <Grid item xs></Grid>
                <Grid item>
                  {selectedRec ? (
                    <Button
                      color="secondary"
                      disabled={!selectedRec.fileDownloadURL}
                      onClick={handleDialogDownloadSTL}
                    >
                      Download STL
                    </Button>
                  ) : (
                    <Tooltip
                      title={
                        user.canReconstruct ? (
                          ''
                        ) : (
                          <Typography variant="body2" color="inherit">
                            {user.cantReconstructReason}
                          </Typography>
                        )
                      }
                      placement="top-start"
                      arrow
                    >
                      {/* Wrap in div so tooltip is visible with disabled button */}
                      <div>
                        <Button
                          color={user.canReconstruct ? 'primary' : 'default'}
                          disabled={
                            selectedRec !== null || !user.canReconstruct
                          }
                          onClick={handleReconstruct}
                        >
                          Reconstruct
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleDeleteReconstruction}
                    disabled={selectedRec === null}
                  >
                    Delete
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose}>Close</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <InfoDialog
        open={!user.hasRunningOps ? dialog : false}
        onClose={handleClose}
        onSelect={handleDialogSelect}
      />
    </div>
  );
}

Info.propTypes = {};

Info.defaultProps = {};

export default observer(Info);

exports.FB_CREDS = {
  apiKey: 'AIzaSyCyBSl05Jt6UqnwcNIRNj_vCSGKAvIUHpI',
  authDomain: 'gcode2l.firebaseapp.com',
  projectId: 'gcode2l',
  storageBucket: 'gcode2l.appspot.com',
  messagingSenderId: '770417199102',
  appId: '1:770417199102:web:b180cfd5c63859209b62af',
  measurementId: 'G-X7XPL6SXLV',
};

exports.ACCEPTED_FILES = '.gcode,.GCODE,.bgcode,.BGCODE,.ufp,.UFP';
exports.ASSET_CACHE_MAX_AGE = 604800; // seconds
exports.ASSET_STORAGE_META = {
  cacheControl: `public,max-age=${exports.ASSET_CACHE_MAX_AGE}`,
};
exports.EDGE_COST_LIMIT = 0.00009;
exports.LIMIT_MEMORY_GB = 4;
exports.LIMIT_TIMEOUT_SEC = 300;

exports.RESOLUTION = {
  min: 0.1,
  max: 1,
  step: 0.05,
  default: 0.55,
};

exports.MAX_EDGES = {
  min: 0,
  max: 100,
  step: 1,
  default: 10,
  defaultFloat: 0.1,
};

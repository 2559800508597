/**
 *
 * Layout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const styles = (theme) => ({
  appFrame: {
    height: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  },
  contentShift: {},
  contentWithoutAppBar: {},
});

const Layout = ({
  classes,
  children,
  appBar,
  appMenu,
  appMenuDocked,
  disableAppBar,
}) => (
  <main className={classes.appFrame}>
    {appBar}
    {appMenuDocked && appMenu}
    <div
      className={classNames(classes.content, {
        [classes.contentShift]: appMenuDocked,
        [classes.contentWithoutAppBar]: disableAppBar,
      })}
    >
      {children}
    </div>
  </main>
);

Layout.propTypes = {
  appBar: PropTypes.node,
  appMenu: PropTypes.element,
  appMenuDocked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  disableAppBar: PropTypes.bool,
};

Layout.defaultProps = {
  disableAppBar: false,
};

export default observer(withStyles(styles)(Layout));

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
// import { useStore } from '../../store';

const useStyles = makeStyles((theme) => ({
  div: {
    width: '100%',
    height: '100%',
    background: 'radial-gradient(#f8f 1%, #003BFF 99%)',
    backgroundPosition: '0px -18px',
  },
}));

function Logo({ width, height }) {
  const classes = useStyles();
  const theme = useTheme();
  // const store = useStore();
  const color = theme.palette.primary.main;

  return (
    <svg width={width} height={height} viewBox="0 0 48 48">
      <g
        id="Path-0"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polyline
          id="Path-1"
          stroke={color}
          strokeWidth="0.5"
          points="47.995895 1 1 1 1 47 47 47 47 17 23 17 17 17 17 31 33 31 33 33 15 33 15 15 48 15"
        ></polyline>
        <polyline
          id="Path-2"
          stroke={color}
          strokeWidth="0.5"
          points="47.99983 3 3 3 3 45 45 45 45 19 23 19 19 19 19 29 35 29 35 35 13 35 13 13 48 13"
        ></polyline>
        <polyline
          id="Path-3"
          stroke={color}
          strokeWidth="0.5"
          points="47.99983 5 5 5 5 43 43 43 43 21 23 21 21 21 21 27 37 27 37 37 11 37 11 11 48 11"
        ></polyline>
        <polyline
          id="Path-5"
          stroke={color}
          strokeWidth="0.5"
          points="47.99983 7 7 7 7 41 41 41 40.833008 23 23 23 23 25 39 25 39 39 22.9999151 39 9 39 9 9 48 9"
        ></polyline>
      </g>
    </svg>
  );
}

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Logo.defaultProps = {
  width: 34,
  height: 34,
};

export default observer(Logo);

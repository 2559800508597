import { toJS, observable, runInAction } from 'mobx';
import { Document } from 'firestorter';
import moment from 'moment';
import { throttle, isEqual } from 'lodash';
import { isServer } from '../utils/env';

class UniversalDocument extends Document {
  _cache = null;
  _lastUpdateKeys = null;
  @observable accessor _local = {};
  @observable accessor _localTimestamp = {};
  options = null;

  constructor(source, options = {}) {
    if (!isServer()) {
      super(source, options);

      if (options.cache) {
        this._cache = options.cache;
      }
      this.options = options;
      return;
    }

    options.mode = 'off';
    super(source, options);
    if (options.cache) {
      this._cache = options.cache;
    }
    this.options = options;

    this.fetch();
  }

  ready() {
    return new Promise((resolve) => {
      super.ready().then((v) => {
        // console.log('DOCUMENT->CACHE:', this.path);
        if (isServer() && this._cache) {
          this._cache.set(this.path, toJS(this.data));
        }
        resolve(v);
      });
    });
  }

  update(fields) {
    if (this.options.autoCreate) {
      return super.set(fields, { merge: true });
    }

    return super.update(fields);
  }

  throttledUpdate = throttle(
    (fields) => runInAction(() => this.update(fields)),
    500,
  );

  log(msg) {
    const enabled = false;
    if (!enabled || isServer()) return;

    console.log(`${this.debugName}:`, msg); // eslint-disable-line no-console
  }

  get hasCache() {
    return this._cache !== null && this._cache.has(this.path);
  }

  get hasData() {
    return super.hasData || (this.hasCache && !super.isLoaded);
  }

  get isLoading() {
    return !this.hasCache && super.isLoading;
  }

  get fromCache() {
    return this.options.fromCache;
  }

  get data() {
    if ((super.isLoading || !super.isLoaded) && this.hasCache) {
      return this._cache.get(this.path);
    }

    return super.data;
  }

  getLocal(key, def) {
    const value = typeof this.data[key] !== 'undefined' ? this.data[key] : def;
    if (typeof this._local[key] === 'undefined' || !this._localTimestamp[key]) {
      // console.log('getLocal: no local, using remote', key);
      return value;
    }

    try {
      const { seconds } = this.snapshot._delegate._document.version.timestamp;
      const remoteTs = moment.unix(seconds);

      if (this._localTimestamp[key].isAfter(remoteTs)) {
        // console.log('getLocal: remote it stale, using local', key);
        return this._local[key];
      }
    } catch (e) {
      //
    }

    // console.log('getLocal: cache is stale, using remote', key);
    runInAction(() => {
      this._localTimestamp[key] = null;
    });
    return value;
  }

  setLocal(key, value) {
    this._local[key] = value;
    this._localTimestamp[key] = moment();
  }

  // Update locally first, then throttled update remote
  // use this.getLocal to use immediate value
  prudentUpdate(fields) {
    const keys = Object.keys(fields);
    for (const k of keys) {
      this.setLocal(k, fields[k]);
    }

    // Different set of fields, flushing throttle
    if (this._lastUpdateKeys && !isEqual(keys, this._lastUpdateKeys)) {
      this.throttledUpdate.flush(); // flush previous
      this.update(fields); // apply immediately, otherwise it will hit throttle because previous was flushed
    } else {
      this.throttledUpdate(fields);
    }
    this._lastUpdateKeys = keys;
  }
}

export default UniversalDocument;

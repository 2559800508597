import {
  computed,
  observable,
  action,
  makeObservable,
  runInAction,
} from 'mobx';
import { createTheme } from '@material-ui/core/styles';
import { dark, light } from '../../common/theme';
import { isServer } from '../utils/env';
import Auth from './Auth';
import User from './User';
import Admin from './Admin';
import SystemSettings from './SystemSettings';

function getPreferredColorScheme() {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }
  return 'dark';
}

class Store {
  _options = {};
  @observable accessor _themeType = null;
  @observable accessor _backdrop = false;
  @observable accessor auth = null;
  @observable accessor _user = null;
  @observable accessor _admin = null;
  @observable accessor router = null;
  @observable accessor _systemSettings = null;

  constructor(cache, router) {
    this._options = { cache, store: this };

    runInAction(() => {
      this.router = router;
      this.auth = new Auth(this._options);
    });

    if (!isServer() && window.matchMedia) {
      const colorSchemeQuery = window.matchMedia(
        '(prefers-color-scheme: dark)',
      );
      colorSchemeQuery.addEventListener('change', () => {
        this.syncThemeWithPreferred();
      });

      setTimeout(() => {
        this.syncThemeWithPreferred();
      }, 20);
    }
  }

  ready() {
    return Promise.all([
      this.auth.ready(),
      this.user && this.user.ready(),
      this.admin && this.admin.ready(),
      this.systemSettings && this.systemSettings.ready(),
    ]);
  }

  dispose() {
    // console.log('Store.dispose()');
  }

  get systemSettings() {
    if (this._systemSettings) return this._systemSettings;

    runInAction(() => {
      this._systemSettings = new SystemSettings(this._options);
    });

    return this._systemSettings;
  }

  @computed get maintenance() {
    return this.systemSettings.maintenance;
  }

  get user() {
    if (!this.auth.signedIn) return null;

    if (this._user) return this._user;

    runInAction(() => {
      this._user = new User(() => `user/${this.auth.uid}`, this._options);
    });

    return this._user;
  }

  get admin() {
    if (!this.auth.signedIn || !this.auth.isAdmin) return null;

    if (this._admin) return this._admin;

    runInAction(() => {
      this._admin = new Admin(this._options);
    });

    return this._admin;
  }

  @computed get themeType() {
    return this._themeType || (this.user && this.user.theme) || 'dark';
  }

  @computed get theme() {
    return createTheme(this.themeType === 'light' ? light : dark);
  }

  @computed get isAutoTheme() {
    return this.user ? this.user.isAutoTheme : true;
  }

  @computed get backdrop() {
    return (
      this._backdrop ||
      (this.user && (this.user.hasRunningOps || !this.user.isInitialized)) ||
      false
    );
  }

  @action setTheme(type, manual = true) {
    if (type !== 'dark' && type !== 'light') {
      throw new Error(`'type' should be 'dark' or 'light', got ${type}`);
    }
    this._themeType = type;
    if (this.user) this.user.setTheme(type);
    if (manual && this.isAutoTheme) this.user.setAutoTheme(false);
  }

  @action setAutoTheme(enabled) {
    this.user.setAutoTheme(enabled);
    if (enabled) {
      this.syncThemeWithPreferred({ force: true });
    }
  }

  @action syncThemeWithPreferred({ force = false } = {}) {
    if (!this.isAutoTheme && !force) return;

    const current = getPreferredColorScheme();
    if (this.themeType !== current) {
      this.setTheme(current, false);
    }
  }

  @action setBackdrop(state) {
    this._backdrop = state;
  }
}

export default Store;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { QRCode } from 'react-qrcode-logo';
import Settings from '../../components/Settings';
import UserModel from '../../store/User';
import AdminModel from '../../store/Admin';
import { LIMIT_MEMORY_GB, LIMIT_TIMEOUT_SEC } from '../../store/constants';
import ReconstructionPreview from '../../components/ReconstructionPreview';
import Rewards from '../../components/Rewards';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  content: {
    height: 640,
  },
  fullWidthContent: {
    height: 640,
    padding: 0,
  },
  tabs: {
    marginTop: theme.spacing(4),
  },
  paper: {
    minWidth: '699px',
    padding: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function LimitsDialog({ open, memory, timeout, manual, onClose, onApply }) {
  const [newMemory, setNewMemory] = React.useState(memory);
  const [newTimeout, setNewTimeout] = React.useState(timeout / 60);
  const [newManual, setNewManual] = React.useState(manual);

  const handleCancel = () => {
    setNewMemory(memory);
    setNewTimeout(timeout / 60);
    setNewManual(manual);
    onClose();
  };
  const handleApply = () => {
    onApply(Number(newMemory), Number(newTimeout * 60), Boolean(newManual));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle id="form-dialog-title">User Resource Limits</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Current limits are {memory}GB of memory and {timeout / 60} minutes
          timeout, set {manual ? 'manually' : 'automatically'}
        </DialogContentText>
        <FormGroup row>
          <TextField
            label="Memory"
            margin="dense"
            fullWidth
            value={newMemory}
            onChange={(e) => setNewMemory(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">GB</InputAdornment>,
            }}
          />
          <TextField
            label="Timeout"
            margin="dense"
            fullWidth
            value={newTimeout}
            onChange={(e) => setNewTimeout(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">minutes</InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={newManual}
                onChange={(e) => setNewManual(e.target.checked)}
                name="manualChecked"
                color="primary"
              />
            }
            label="Manual"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LimitsDialog.propTypes = {
  open: PropTypes.bool,
  memory: PropTypes.number,
  timeout: PropTypes.number,
  manual: PropTypes.bool,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
};

function SupporterItemComponent({
  donation,
  total,
  latest,
  onClick,
  open,
  expandable,
}) {
  return (
    <ListItem button={!!onClick} onClick={onClick ? onClick : () => {}}>
      <ListItemText
        primary={
          <>
            ${total} ･ {donation.supporter_name} ･ {donation.payer_email}
          </>
        }
        secondary={
          <>
            {donation.country} ･ {donation.support_currency} ･ {latest}
          </>
        }
      />
      {open ? <ExpandLess /> : expandable && <ExpandMore />}
    </ListItem>
  );
}

SupporterItemComponent.propTypes = {
  donation: PropTypes.object,
  total: PropTypes.number,
  latest: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  expandable: PropTypes.bool,
};

SupporterItemComponent.defaultProps = {
  donation: {
    supporter_name: 'N/A',
    payer_email: 'N/A',
    country: 'N/A',
    suppor_currency: 'N/A',
  },
};

const SupporterItem = observer(SupporterItemComponent);

function DonationItemComponent({ donation, className }) {
  return (
    <ListItem className={className}>
      <ListItemText
        primary={
          <>
            {donation.support_coffees} coffees x {donation.support_coffee_price}{' '}
            = $
            {Number(
              donation.support_coffees * Number(donation.support_coffee_price),
            )}
            {donation.is_refunded && (
              <>
                <br />
                <span style={{ color: 'red' }}>REFUNDED</span>
              </>
            )}
            {!donation.support_visibility && (
              <>
                <br />
                Invisible
              </>
            )}
            {donation.support_hidden ? (
              <>
                <br />
                Hidden
              </>
            ) : (
              false
            )}
          </>
        }
        secondary={`${donation.support_note || '...'}`}
      ></ListItemText>
    </ListItem>
  );
}

DonationItemComponent.propTypes = {
  donation: PropTypes.object,
  className: PropTypes.string,
};

const DonationItem = observer(DonationItemComponent);

function UserComponent({ user, userName, userEmail }) {
  const [limitsDialogOpen, setLimitsDialogOpen] = React.useState(false);

  const fd = user.donations.length > 0 ? user.donations[0].data : null;

  const handleLimitsChange = () => {
    setLimitsDialogOpen(true);
  };

  const handleLimitsApply = (memory, timeout, manual) => {
    user.setLimits(memory, timeout, manual);
  };

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <List
            subheader={<ListSubheader disableSticky>Account</ListSubheader>}
          >
            <ListItem>
              <ListItemText
                primary={`${userName} (${user.id})`}
                secondary={userEmail}
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  user.extendedLimits
                    ? `Limits extended to ${user.memoryLimit} GB of memory and 
                    ${parseInt(user.timeoutLimit / 60)} minutes timeout`
                    : `Standard resource limits of ${LIMIT_MEMORY_GB}GB / ${parseInt(
                        LIMIT_TIMEOUT_SEC / 60,
                      )} minutes apply`
                }
                secondary={
                  user.isLimitsManual
                    ? 'Limits are set manually'
                    : 'Limits are set automatically'
                }
              />
              <ListItemSecondaryAction>
                <Button color="primary" onClick={handleLimitsChange}>
                  Change
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List
            subheader={<ListSubheader disableSticky>Donations</ListSubheader>}
          >
            {fd && (
              <SupporterItem
                donation={fd}
                total={user.donationsTotal}
                latest={user.latestDonation}
              />
            )}
            {user.donations.map((d) => (
              <DonationItem key={d.id} donation={d.data} />
            ))}
          </List>
        </Grid>
      </Grid>
      {user.limits.isLoaded && (
        <LimitsDialog
          open={limitsDialogOpen}
          memory={user.memoryLimit}
          timeout={user.timeoutLimit}
          manual={user.isLimitsManual}
          onClose={() => setLimitsDialogOpen(false)}
          onApply={handleLimitsApply}
        />
      )}
    </div>
  );
}

UserComponent.propTypes = {
  user: PropTypes.instanceOf(UserModel),
  userName: PropTypes.string,
  userEmail: PropTypes.string,
};

const User = observer(UserComponent);

function DonationsComponent({ supporters }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const handleClick = (id) => {
    if (!id || id === open) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };
  return (
    <List component="nav">
      {supporters.map((s) => {
        const id = s.id;
        return (
          <div key={s.id}>
            <SupporterItem
              donation={s.donations[0] && s.donations[0].data}
              total={s.donationsTotal}
              latest={s.latestDonation}
              onClick={() => handleClick(id)}
              open={open === id}
              expandable={s.donations.length > 0}
            />
            <Collapse in={open === id} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {s.donations.map((d) => (
                  <DonationItem
                    key={d.id}
                    className={classes.nested}
                    donation={d.data}
                  />
                ))}
              </List>
            </Collapse>
          </div>
        );
      })}
    </List>
  );
}

DonationsComponent.propTypes = {
  supporters: PropTypes.array,
};

const Donations = observer(DonationsComponent);

let lastSelectedTab = 'welcome';
function ControlPanelDialog({
  open,
  onClose,
  onSelect,
  user,
  userName,
  userEmail,
  admin,
}) {
  const classes = useStyles();

  if (open) {
    lastSelectedTab = open;
  }

  const handleChange = (event, newValue) => {
    onSelect(newValue);
  };

  return (
    <Dialog
      open={
        open === 'user' ||
        open === 'donations' ||
        open === 'bmcLog' ||
        open === 'system'
      }
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.paper,
      }}
    >
      <TabContext value={open || lastSelectedTab}>
        <TabList
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          centered
          // variant="fullWidth"
        >
          <Tab label="User" value="user" />
          <Tab label="Donations" value="donations" />
          <Tab label="BMC Log" value="bmcLog" />
          <Tab label="System" value="system" />
        </TabList>
        <DialogContent
          className={
            open === 'donate' ? classes.fullWidthContent : classes.content
          }
        >
          <TabPanel value="user">
            <User user={user} userName={userName} userEmail={userEmail} />
          </TabPanel>
          <TabPanel value="donations">
            <Donations supporters={admin.supporters} />
          </TabPanel>
          <TabPanel value="bmcLog"></TabPanel>
          <TabPanel value="donate"></TabPanel>
        </DialogContent>
      </TabContext>
      <DialogActions
        disableSpacing={true}
        classes={{ root: classes.dialogActions }}
      >
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

ControlPanelDialog.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  user: PropTypes.instanceOf(UserModel),
  admin: PropTypes.instanceOf(AdminModel),
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

ControlPanelDialog.defaultProps = {
  open: false,
};

export default observer(ControlPanelDialog);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import NewIcon from '@material-ui/icons/AddCircleTwoTone';
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import Model from '../../store/Model';
import ReconstructionControls from '../ReconstructionControls';
import ReconstructionView from '../ReconstructionView';
import Label from '../Label';
import Reconstruction from '../../store/Reconstruction';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import UnsupportedIcon from '@material-ui/icons/HighlightOffTwoTone';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  tab: {
    width: 80,
    minWidth: 80,
  },
  recsListContainer: {
    // height: '100%',
    paddingTop: theme.spacing(3),
    height: 640,
    // minHeight: 300,
    overflowY: 'scroll',
  },
  controlsContainer: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  listItem: {
    paddingLeft: theme.spacing(4),
  },
  name: {
    overflowWrap: 'break-word',
  },
}));

const RecIcon = ({ ready, error }) => {
  if (error) {
    return <ErrorIcon fontSize="large" color="secondary" />;
  }
  if (ready) {
    return <CheckIcon fontSize="large" />;
  }

  return (
    <div style={{ paddingLeft: 3 }}>
      <CircularProgress size={28} thickness={5} />
    </div>
  );
};

RecIcon.propTypes = {
  ready: PropTypes.bool,
  error: PropTypes.bool,
  selected: PropTypes.bool,
};

function ModelView({
  model,
  rec,
  overlay,
  showEdges,
  resolution,
  optimizeLayers,
  maxEdges,
  parallelProjection,
  userWithExtendedLimits,
  resolutionExceeded,
  onRecSelected,
  onResolutionChange,
  onMaxEdgesChange,
  onOptimizeLayersChange,
  onOpenDonate,
}) {
  const classes = useStyles();

  const handleRecSelect = (r) => () => {
    onRecSelected(r);
  };

  if (model === null || typeof model === 'undefined') {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item xs={12} sm={5}>
          <div className={classes.recsListContainer}>
            <List className={classes.recsList}>
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemText
                  primary={
                    <Typography variant="h6" className={classes.name}>
                      {model.filename}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {model.slicer} {model.slicerVersion}
                      </Typography>
                      {` - ${model.fileSize}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider component="li" />
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemText
                  primary={`${model.numLayers} layers`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {model.minLayerHeight} - {model.maxLayerHeight} mm
                      </Typography>
                      {' - layer height'}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider component="li" />
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemText
                  primary={`${model.numExtrusions} extrusions`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {model.minExtrusionWidth} - {model.maxExtrusionWidth} mm
                      </Typography>
                      {' - extrusion width'}
                    </React.Fragment>
                  }
                />
              </ListItem>
              {!model.tagsAvailable && (
                <div>
                  <Divider component="li" />
                  <ListItem
                    alignItems="flex-start"
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <WarningIcon color="secondary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Label title="No extrusion tags">
                          Without tags, the tool will not be able to remove
                          supports and will use more resources. If the file
                          contains multiple models, only the largest model in
                          the set will be reconstructed.
                        </Label>
                      }
                      secondary="Optimization may introduce artifacts"
                    />
                  </ListItem>
                </div>
              )}
              {model.unsupportedCommands && (
                <div>
                  <Divider component="li" />
                  <ListItem
                    alignItems="flex-start"
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <UnsupportedIcon color="secondary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Label title="Unsupported commands">
                          This file contains GCODE commands that are not
                          currently supported by the tool. Some parts of the
                          model cannot be reconstructed. You can still run the
                          reconstruction, but the model will likely be broken.
                        </Label>
                      }
                      secondary="Part of the model cannot be reconstructed"
                    />
                  </ListItem>
                </div>
              )}
              <ListItem
                button
                onClick={handleRecSelect(null)}
                selected={rec === null}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <NewIcon color="primary" fontSize="large" />
                </ListItemIcon>
                <ListItemText
                  primary="New"
                  secondary="Create new reconstruction"
                />
              </ListItem>
              {model.recsSortedByDate.map((r) => (
                <ListItem
                  button
                  key={r.id}
                  onClick={handleRecSelect(r)}
                  selected={rec && rec.id === r.id}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <RecIcon
                      ready={r.isReady}
                      error={r.isFailed}
                      selected={rec === r}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${r.resolution}mm ･ ${
                      r.optimizeLayers ? 'on' : 'off'
                    } ･ ${r.maxEdges}%`}
                    secondary={`${r.objectNameAndId} ･ ${r.sizeOrStatus}`}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          {!rec ? (
            <div className={classes.controlsContainer}>
              <ReconstructionControls
                resolution={resolution}
                maxEdges={maxEdges}
                optimizeLayers={optimizeLayers}
                startLayer={model.startLayer}
                numLayers={model.numLayers}
                objects={model.objects}
                selectedObject={model.selectedObjectUID}
                onResolutionChange={onResolutionChange}
                onMaxEdgesChange={onMaxEdgesChange}
                onOptimizeLayersChange={onOptimizeLayersChange}
                onStartLayerChange={(v) => model.setStartLayer(v)}
                onSelectObject={(uid) => model.selectObject(uid)}
                resolutionExceeded={resolutionExceeded}
              />
            </div>
          ) : (
            <ReconstructionView
              reconstruction={rec}
              showEdges={showEdges}
              parallelProjection={parallelProjection}
              overlay={overlay}
              userWithExtendedLimits={userWithExtendedLimits}
              onOpenDonate={onOpenDonate}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

ModelView.propTypes = {
  model: PropTypes.instanceOf(Model),
  rec: PropTypes.instanceOf(Reconstruction),
  overlay: PropTypes.bool,
  resolution: PropTypes.number,
  optimizeLayers: PropTypes.bool,
  maxEdges: PropTypes.number,
  showEdges: PropTypes.bool,
  parallelProjection: PropTypes.bool,
  userWithExtendedLimits: PropTypes.bool,
  resolutionExceeded: PropTypes.bool,
  onRecSelected: PropTypes.func,
  onResolutionChange: PropTypes.func,
  onMaxEdgesChange: PropTypes.func,
  onOptimizeLayersChange: PropTypes.func,
  onOpenDonate: PropTypes.func,
};

ModelView.defaultProps = {
  overlay: false,
};

export default observer(ModelView);

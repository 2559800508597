import React, { useState } from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AutoIcon from '@material-ui/icons/BrightnessAuto';
import MoonIcon from '@material-ui/icons/Brightness4';
import SunIcon from '@material-ui/icons/Brightness7';
import Tooltip from '@material-ui/core/Tooltip';
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import { useStore } from '../../store';
import GoogleSignInButton from '../../components/GoogleSignInButton';
import EmailLoginDialog from '../EmailLoginDialog/EmailLoginDialog';
import Rewards from '../../components/Rewards';
import { LIMIT_MEMORY_GB, LIMIT_TIMEOUT_SEC } from '../../store/constants';

const useStyles = makeStyles((theme) => ({
  groupButton: {
    color: theme.palette.text.secondary,
  },
  root: {},
  selected: {
    color: theme.palette.text.primary + ' !important',
    background: alpha(theme.palette.primary.main, 0.17) + ' !important',
  },
}));

function Settings() {
  const classes = useStyles();
  const store = useStore();
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const { user, auth, themeType, isAutoTheme } = store;
  const theme = () => (isAutoTheme ? 'auto' : themeType);

  const handleLogout = () => {
    user.setDialog(false);
    auth.logout();
  };

  const handleMigrateToGoogle = async () => {
    try {
      await auth.migrateToGoogle({ redirect: false });
    } catch (error) {
      console.log('migrageToGoogle error', error);
    }
  };

  const handleMigrateToEmail = () => {
    setEmailDialogOpen(true);
  };

  const handleThemeChange = (e, value) => {
    if (value === 'auto') {
      store.setAutoTheme(true);
    } else if (value) {
      store.setAutoTheme(false);
      store.setTheme(value);
    }
  };

  const handleEmailDialogClose = () => {
    setEmailDialogOpen(false);
  };

  const handleClaimRewards = () => {
    if (user.hasRunningOps) {
      return;
    }
    user.claimRewards();
  };

  const groupButtonClasses = {
    root: classes.groupButton,
    selected: classes.selected,
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <List
            subheader={<ListSubheader disableSticky>Account</ListSubheader>}
          >
            <ListItem>
              <ListItemText
                primary={
                  !auth.isAnon
                    ? auth.displayName || auth.email
                    : 'You are currently using a temporary account. Your files will be deleted after the session ends. To keep your files, sign in with Google or email and password. The files from the current session will be moved to the new account.'
                }
                secondary={!auth.isAnon && auth.displayName && auth.email}
              ></ListItemText>
              {!auth.isAnon && (
                <ListItemSecondaryAction>
                  <Button color="primary" onClick={handleLogout}>
                    Sign out
                  </Button>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {auth.isAnon && (
              <ListItem>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs>
                    <GoogleSignInButton
                      fullWidth
                      onClick={handleMigrateToGoogle}
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs>
                    <Button fullWidth onClick={handleMigrateToEmail}>
                      Sign In With Email
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item>
          <List subheader={<ListSubheader>Settings</ListSubheader>}>
            <ListItem disabled={user.isAnon}>
              <ListItemText
                primary="Theme"
                secondary={'Choose between dark, light or auto'}
              ></ListItemText>
              <ListItemSecondaryAction>
                <ToggleButtonGroup
                  size="small"
                  value={theme()}
                  exclusive
                  onChange={handleThemeChange}
                >
                  <ToggleButton
                    disabled={user.isAnon}
                    value="dark"
                    classes={groupButtonClasses}
                  >
                    <MoonIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton
                    disabled={user.isAnon}
                    value="auto"
                    classes={groupButtonClasses}
                  >
                    <Tooltip
                      arrow
                      placement="top"
                      title="Automatically switch(based on OS settings)"
                    >
                      <AutoIcon fontSize="small" />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                    disabled={user.isAnon}
                    value="light"
                    classes={groupButtonClasses}
                  >
                    <SunIcon fontSize="small" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <List subheader={<ListSubheader>Extended Limits</ListSubheader>}>
            <ListItem>
              <ListItemText
                secondary={
                  user.extendedLimits
                    ? `Limits extended to ${user.memoryLimit} GB of memory and 
                    ${parseInt(user.timeoutLimit / 60)} minutes timeout`
                    : `Standard resource limits of ${LIMIT_MEMORY_GB}GB / ${parseInt(
                        LIMIT_TIMEOUT_SEC / 60,
                      )} minutes apply`
                }
                primary={
                  user.donationsTotal > 0
                    ? `You've donated $${user.donationsTotal}. Thank you!`
                    : '$0 donated'
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid item>
        <Box ml={4} mr={4} mt={2}>
          <Rewards amount={user.donationsTotal} />
        </Box>
        <Box>
          {!auth.isAnon ? (
            <Alert severity="info">
              If you&apos;ve made a donation but it doesn&apos;t appear on this
              page, make sure the email you used on Buy Me A Coffee matches the
              email you use to log in to gcode2l. If the problem persists,
              contact me at Buy Me A Cofee.
            </Alert>
          ) : (
            <Alert severity="info">
              To extend resource limits, you will need permanent account with
              the same email you&apos;ve used on Buy Me A Coffee.
            </Alert>
          )}
        </Box>
      </Grid>
      <EmailLoginDialog
        open={emailDialogOpen}
        onClose={handleEmailDialogClose}
        onGoogleLogin={handleMigrateToGoogle}
        migrate
      />
    </div>
  );
}

Settings.propTypes = {};

Settings.defaultProps = {};

export default observer(Settings);

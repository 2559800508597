import { createTheme, alpha } from '@material-ui/core/styles';
import { amber, deepPurple, common } from '@material-ui/core/colors';

export const dark = {
  palette: {
    type: 'dark',
    text: {
      secondary: alpha('#C3C9D8', 0.7),
    },
    // primary: {
    //   // main: '#32ccff',
    //   main: '#a6f4ff',
    // },
    primary: {
      main: '#b3e5fc',
    },
    secondary: {
      main: '#ff0000',
      // main: '#00ffd0',
      // main: '#ff00a5',
    },
    success: {
      light: '#62D0A0',
      main: '#299E71',
      dark: '#293F3B',
    },
    warning: {
      main: '#c7a500',
    },
    error: {
      main: '#D65959',
      dark: '#613641',
    },
    background: {
      default: '#131317',
      paper: '#1E1E1E',
      drawer: '#15161A',
      preview: '#390080',
    },
    divider: alpha('#838398', 0.12),
    stl: {
      // previewBackground: [0.2, 0, 0.5, 0],
      previewBackground: [0, 0, 0, 0],
      background: [0.118, 0.118, 0.118],
      edge: [1, 0, 0],
      color: [1, 1, 1],
      // color: [0.715, 0.718, 1.0],
    },
  },
  logo: {
    bmc: '/bmc-logo-dark.svg',
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    useNextVariants: true,
  },
  sizes: {
    appMenuWidth: 72,
    appBarHeight: 56,
    dataAreaMaxWidth: 680,
    chartHeight: 380,
  },
  blur: 'blur(8px)',
  shadow: `0 0 2px rgba(255, 255, 255, 0.06)`,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '#root': {
          height: '100vh',
        },
        '*::-webkit-scrollbar': {
          width: 5,
          height: 8,
        },
        '*::-webkit-scrollbar-thumb': {
          background: alpha('#fff', 0.16),
        },
        '.firebase-emulator-warning': { display: 'none' },
        'a, a code': {
          color: '#32ccff',
          textDecoration: 'none',
          cursor: 'pointer',
        },
        'a:hover, a code:hover': {
          textDecoration: 'underline',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(255,255,255, 0.04)',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

export const light = {
  palette: {
    type: 'light',
    text: {
      secondary: alpha('#1E2231', 0.6),
    },
    primary: {
      main: '#390080',
    },
    secondary: {
      main: '#ff1744',
    },
    success: {
      main: '#34a853',
    },
    warning: {
      main: '#c7a500',
    },
    error: {
      main: '#ea4335',
    },
    background: {
      default: '#fafafa', //'#F0F2F5',
      paper: common.white,
      drawer: '#F0F2F5',
      preview: '#d3e9ff',
    },
    divider: alpha('#000', 0.05),
    stl: {
      // previewBackground: [0.828, 0.915, 1.0],
      previewBackground: [0, 0, 0, 0],
      background: [1, 1, 1],
      color: [0.4, 0.4, 0.4],
      edge: [1, 0, 0],
    },
  },
  logo: {
    bmc: '/bmc-logo.svg',
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    useNextVariants: true,
  },
  sizes: {
    appMenuWidth: 72,
    appBarHeight: 56,
    dataAreaMaxWidth: 680,
    chartHeight: 380,
  },
  blur: 'blur(8px)',
  shadow: `0 0 2px rgba(0, 0, 0, 0.12)`,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '#root': {
          height: '100vh',
        },
        '*::-webkit-scrollbar': {
          width: 5,
          height: 8,
        },
        '*::-webkit-scrollbar-thumb': {
          background: `rgba(0,0,0,.32)`,
        },
        '.firebase-emulator-warning': { display: 'none' },
        'a, a code': {
          color: '#ff1744',
          textDecoration: 'none',
          cursor: 'pointer',
        },
        'a:hover, a code:hover': {
          textDecoration: 'underline',
        },
      },
    },
    MuiTableCell: {
      root: {
        // borderBottom: '1px solid rgba(255,255,255, 0.04)',
        borderBottom: '1px solid rgba(0,0,0, 0.04)',
      },
    },
    MuiListItem: {
      divider: {
        // borderBottom: '1px solid rgba(255,255,255, 0.04)',
        borderBottom: '1px solid rgba(0,0,0, 0.06)',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

export default createTheme(dark);

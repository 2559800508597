import { makeObservable, computed } from 'mobx';
import UniversalDocument from './UniversalDocument';

class SystemSettings extends UniversalDocument {
  constructor(options) {
    super(() => '/system/settings', options);

    makeObservable(this);
  }

  ready() {
    return super.ready();
  }

  @computed get maintenance() {
    if (!this.hasData) {
      return false;
    }

    return this.data.maintenance || false;
  }
}

export default SystemSettings;

import { action, observable, computed, runInAction } from 'mobx';
import 'firebase/compat/storage';
import { Collection } from 'firestorter';
import Model from './Model';
import User from './User';
import Supporter from './Supporter';
import { uniqWith } from 'lodash';

class Admin {
  _options = null;
  @observable accessor _historyCollection = null;
  @observable accessor _supportersCollection = null;
  @observable accessor _selectedModel = null;
  @observable accessor _dialog = false;
  @observable accessor _selectedRec = false;
  @observable accessor _showEdges = null;
  @observable accessor _parallelProjection = null;
  @observable accessor _infoOpen = null;

  constructor(options) {
    this._options = options;
  }

  ready() {
    return Promise.all([this.historyCollection.ready()]);
  }

  get historyCollection() {
    if (this._historyCollection) return this._historyCollection;

    runInAction(() => {
      this._historyCollection = new Collection(`history`, {
        query: (ref) => ref.orderBy('createdAt', 'desc').limit(50),
      });
    });
    return this._historyCollection;
  }

  get supportersCollection() {
    if (this._supportersCollection) return this._supportersCollection;

    runInAction(() => {
      this._supportersCollection = new Collection('supporter', {
        createDocument: (s, o) =>
          new Supporter(s, { ...this.options, ...o, autoCreate: false }),
      });
    });

    return this._supportersCollection;
  }

  @computed get historyModels() {
    return this.historyCollection.docs;
  }

  @computed get historyModelsSortedByDate() {
    return this.historyModels.slice().sort((a, b) => b.createdAt - a.createdAt);
  }

  @computed get models() {
    return uniqWith(
      this.historyModelsSortedByDate,
      (a, b) => a.data.modelId === b.data.modelId,
    ).map(
      (m) =>
        new Model(
          `user/${m.data.userId}/models/${m.data.modelId}`,
          this._options,
        ),
    );
  }

  @computed get modelsSortedByDate() {
    return this.models.slice().sort((a, b) => b.createdAt - a.createdAt);
  }

  @computed get selectedModel() {
    const id = this._selectedModel;
    if (id === null) {
      return null;
    }
    return this.models.find((m) => m.id === id);
  }

  @computed get selectedRec() {
    if (!this.selectedModel) {
      return null;
    }

    const recId = this._selectedRec;
    if (recId === false) {
      return this.selectedModel.selectedRec;
    }
    return this.selectedModel.recs.find((r) => r.id === recId) || null;
  }

  @computed get showEdges() {
    if (this._showEdges === null && this.selectedModelUser) {
      return this.selectedModelUser.showEdges;
    }
    return this._showEdges;
  }

  @computed get parallelProjection() {
    if (this._parallelProjection === null && this.selectedModelUser) {
      return this.selectedModelUser.parallelProjection;
    }
    return this._parallelProjection;
  }

  @computed get infoOpen() {
    if (this._infoOpen === null && this.selectedModelUser) {
      return this.selectedModelUser.infoOpen;
    }
    return this._infoOpen;
  }

  @computed get selectedModelUser() {
    const modelId = this._selectedModel;
    if (modelId === null) {
      return null;
    }
    const hm = this.historyModels.find((m) => m.data.modelId === modelId);
    const userId = hm.data.userId;

    return new User(`user/${userId}`);
  }

  modelMeta(modelId) {
    if (this.historyModels.length === 0) return {};
    return this.historyModels.find((m) => m.data.modelId == modelId).data;
  }

  // modelUsername(modelId) {
  //   const m = this.modelMeta(modelId);
  //   if (!m.userEmail) {
  //     return `${m.userId} (Anonymous)`;
  //   }
  //   if (!m.userName) {
  //     return m.userEmail;
  //   }
  //   return `${m.userName} (${m.userEmail})`;
  // }

  modelUsername(modelId) {
    const m = this.modelMeta(modelId);
    if (!m.userEmail) {
      return 'Anonymous User';
    }
    if (!m.userName) {
      return 'No Name';
    }
    return m.userName;
  }

  modelEmail(modelId) {
    const m = this.modelMeta(modelId);
    if (!m.userEmail) {
      return m.userId;
    }
    return m.userEmail;
  }

  modelIsSupporter(modelId) {
    const m = this.modelMeta(modelId);
    return Boolean(m.supporter);
  }

  @computed get dialog() {
    return this._dialog;
  }

  @computed get supporters() {
    // return this.supportersCollection.docs;
    return this.supportersCollection.docs
      .slice()
      .sort((a, b) => b.data.latestDonation - a.data.latestDonation);
  }

  @action setSelectedModel(model) {
    this._selectedModel = model ? model.id : null;
  }

  @action setSelectedRec(model, rec) {
    this._selectedRec = rec && rec.id;
    // console.log(this._selectedRec);
  }

  @action setShowEdges(enabled) {
    this._showEdges = enabled;
  }

  @action setParallelProjection(enabled) {
    this._parallelProjection = enabled;
  }

  @action setInfoOpen(open) {
    this._infoOpen = open;
  }

  @action setDialog(key) {
    if (!key) {
      this._selectedModel = null;
      this._selectedRec = false;
      this._showEdges = null;
      this._parallelProjection = null;
      this._infoOpen = null;
    }
    this._dialog = key;
  }
}

export default Admin;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Alert } from '@material-ui/lab';

import { useInterval } from '../../utils/hooks';
import Reconstruction from '../../store/Reconstruction';
import ReconstructionPreview from '../../components/ReconstructionPreview';

function RecError({ error }) {
  let text = '';
  switch (error) {
    case 'memory':
      text =
        'Memory limit exceeded. Try to increase resolution and turn on layers optimization to reduce memory usage.';
      break;
    case 'timeout':
      text = 'Time limit exceeded. Try to turn off mesh simplification.';
      break;
    case 'unknown':
    default:
      text =
        'Reconstruction failed. The file has unknown format or selected options is not appropriate for it.';
  }
  return <Alert severity="error">{text}</Alert>;
}
RecError.propTypes = {
  error: PropTypes.string.isRequired,
};

function Title({ children }) {
  return <Typography gutterBottom>{children}</Typography>;
}
Title.propTypes = {
  children: PropTypes.node.isRequired,
};

function InfoItem({ label, value }) {
  return (
    <div style={{ height: 41 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{value}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
InfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

function InfoOverlayComponent({ rec }) {
  return (
    <div style={{ width: '70%' }}>
      <Grid container direction="column" spacing={2}>
        {/* <Grid item>
          <InfoItem label="ID" value={rec.id} />
          <Divider />
        </Grid> */}

        <Grid item>
          <InfoItem label="Resolution" value={`${rec.resolution} mm`} />
          <Divider />
        </Grid>

        <Grid item>
          <InfoItem
            label="Optimization"
            value={rec.optimizeLayers ? 'On' : 'Off'}
          />
          <Divider />
        </Grid>

        <Grid item>
          <InfoItem
            label="Edges"
            value={`${rec.maxEdges}% (${parseInt(
              rec.edgesRatio * 100,
            )}% actual)`}
          />
          <Divider />
        </Grid>

        <Grid item>
          <InfoItem label="Start Layer" value={rec.startLayer} />
        </Grid>

        <Grid item></Grid>
        <Grid item></Grid>

        <Grid item>
          <InfoItem label="Memory" value={rec.usedMemoryString} />
          <Divider />
        </Grid>

        <Grid item>
          <InfoItem label="Time" value={rec.reconstrutionTime} />
        </Grid>
      </Grid>
    </div>
  );
}
InfoOverlayComponent.propTypes = {
  rec: PropTypes.instanceOf(Reconstruction).isRequired,
};
const InfoOverlay = observer(InfoOverlayComponent);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  preview: {
    height: '100%',
  },
  progressView: {
    height: '100%',
    width: '100%',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  progressBar: {
    // marginTop: theme.spacing(1),
  },
}));

function ReconstructionView({
  reconstruction,
  showEdges,
  parallelProjection,
  overlay,
  userWithExtendedLimits,
  onOpenDonate,
}) {
  const classes = useStyles();
  const [time, setTime] = useState(null);
  const [timeText, setTimeText] = useState(false);
  const {
    reconstructed,
    isReady,
    isFailed,
    processedLayers,
    startAt,
    optimizeLayers,
    error,
    maxEdges,
    processedAt,
    usedMemoryPercentage,
    usedMemoryString,
    slotTimeout,
    slotTimeoutString,
    slotMemoryString,
    isLimitsExtended,
    numLayers,
  } = reconstruction;

  const layerProg = numLayers < 1 ? 0 : (processedLayers / numLayers) * 100;
  const totalProg = reconstructed
    ? 100
    : (optimizeLayers && layerProg < 100) || isFailed
      ? 0
      : -1;

  const updateTime = () => {
    const endAt = isFailed ? processedAt : moment();
    const diff = moment(startAt).add(slotTimeout, 'seconds').diff(endAt);
    const diffSec = moment.duration(diff, 'milliseconds').asSeconds();
    const runningMsecs = moment(startAt).diff(endAt);
    setTimeText(
      diff > 0
        ? moment.duration(runningMsecs, 'milliseconds').humanize(false)
        : false,
    );

    setTime(100 - (diffSec / slotTimeout) * 100);
  };

  useInterval(
    () => {
      if (isReady && time !== null) return;
      updateTime();
    },
    1000,
    { fireImmediately: true },
  );

  if (!isReady) {
    return (
      <div className={classes.root}>
        <div className={classes.progressView}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Resources</Typography>
              <Typography
                paragraph
                variant="body1"
                align="justify"
                color="textSecondary"
              >
                This reconstruction instance can use a maximum of{' '}
                {slotMemoryString} of memory and take no more than{' '}
                {slotTimeoutString}. If the process exceeds these limits, it
                will be terminated.
              </Typography>
              {!isReady &&
                !isFailed &&
                timeText &&
                userWithExtendedLimits &&
                !isLimitsExtended && (
                  <Alert severity="warning">
                    The default limits apply to this reconstruction because
                    another reconstruction with extended limits is already in
                    progress.
                  </Alert>
                )}
              {!userWithExtendedLimits && (
                <Typography
                  variant="body1"
                  align="justify"
                  color="textSecondary"
                >
                  You can extend these limits and help me pay for servers by
                  donating to the project,{' '}
                  <a onClick={onOpenDonate}>click here</a> to learn more.
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Title>Memory</Title>
              <LinearProgress
                className={classes.progressBar}
                variant="determinate"
                value={usedMemoryPercentage}
                color={error === 'memory' ? 'secondary' : 'primary'}
              />
              <Typography variant="caption">
                {usedMemoryString} / {slotMemoryString}
              </Typography>
            </Grid>
            <Grid item>
              <Title>Time</Title>
              <LinearProgress
                className={classes.progressBar}
                variant="determinate"
                value={time}
                color={error === 'timeout' ? 'secondary' : 'primary'}
              />
              <Typography variant="caption">
                {timeText
                  ? `${timeText} / ${slotTimeoutString}`
                  : 'Starting...'}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography variant="body2" align="justify" color="textSecondary">
                This reconstruction being processed on Goole Cloud, and add to
                bill for me to pay, please, consider donating to the project so
                I don&apos;t have to shut it down. Click{' '}
                <a onClick={handleDonateClick}>here to read more</a>, the
                reconstruction will not be interrupted.
              </Typography>
            </Grid> */}

            {/* <Grid item></Grid> */}

            <Grid item>
              <Typography variant="h6">Reconstruction</Typography>
            </Grid>
            {optimizeLayers && (
              <Grid item>
                <Title>Optimize Layers</Title>
                <LinearProgress
                  className={classes.progressBar}
                  variant="determinate"
                  value={layerProg}
                />
              </Grid>
            )}
            <Grid item>
              <Title>Generate Mesh</Title>
              <LinearProgress
                className={classes.progressBar}
                variant={totalProg < 0 ? 'indeterminate' : 'determinate'}
                value={totalProg}
              />
            </Grid>
            {maxEdges < 100 && (
              <Grid item>
                <Title>Simplify Mesh</Title>
                <LinearProgress
                  className={classes.progressBar}
                  variant={
                    reconstruction.simplificationProgress <
                    reconstruction.simplificationCost
                      ? 'buffer'
                      : 'determinate'
                  }
                  value={reconstruction.simplificationProgress}
                  valueBuffer={reconstruction.simplificationCost}
                />
              </Grid>
            )}
            {isFailed && (
              <Grid item>
                <RecError error={error} />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ReconstructionPreview
        className={classes.preview}
        url={reconstruction.fileDownloadURL}
        showEdges={showEdges}
        parallelProjection={parallelProjection}
        overlay={overlay && <InfoOverlay rec={reconstruction} />}
        label={reconstruction.isReady && 'Loading Preview'}
        zoom={1.3}
      />
    </div>
  );
}

ReconstructionView.propTypes = {
  reconstruction: PropTypes.instanceOf(Reconstruction).isRequired,
  showEdges: PropTypes.bool,
  parallelProjection: PropTypes.bool,
  overlay: PropTypes.bool,
  userWithExtendedLimits: PropTypes.bool,
  onOpenDonate: PropTypes.func,
};

ReconstructionView.defaultProps = {
  showEdges: false,
  parallelProjection: false,
  overlay: false,
  userWithExtendedLimits: false,
};

export default observer(ReconstructionView);

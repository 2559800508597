/**
 *
 * Placeholder
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Loader from '../Loader';
const styles = (theme) => ({
  content: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  small: {
    margin: `${theme.spacing(4)}px auto`,
    fontSize: '1.2rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  large: {
    margin: `${theme.spacing(12)}px auto`,
    width: 460,
  },
  color: {
    color: theme.palette.grey[600],
  },
});

function Placeholder({
  classes,
  style,
  text,
  children,
  loading,
  size,
  inputClassName,
}) {
  return (
    <div style={style}>
      <div className={classes.content}>
        {loading ? (
          <div className={classes[size]}>
            <Loader />
          </div>
        ) : (
          <div className={classNames(classes[size], inputClassName)}>
            <Typography variant="h6">{text}</Typography>
            <br />
            {children && (
              <Typography variant="body1" color="textSecondary">
                {children}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  loading: PropTypes.bool,
  size: PropTypes.string,
  fontColor: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.object,
};

Placeholder.defaultProps = {
  inputClassName: '',
  text: 'Placeholder text',
  loading: false,
  size: 'large',
};

export default withStyles(styles)(Placeholder);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1.5),
  },
  intro: {
    // paddingTop: '150px',
  },
  maintenanceIcon: {
    fontSize: 500,
    color: theme.palette.warning.main,
  },
}));

export function Maintenance() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item>
        <div className={classes.intro}>
          <Box display="flex" justifyContent="center">
            <WarningIcon className={classes.maintenanceIcon} />
          </Box>
          <Typography variant="h2">UNDER MAINTENANCE</Typography>
          <Typography variant="body1" gutterBottom>
            Please come back later.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

Maintenance.propTypes = {};

export default observer(Maintenance);
